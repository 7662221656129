import React from "react";
import Modal from "../components/modal/Modal";
import { ResumeLinkModal } from "../modals";

export const ResumeLink = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const [isOpen, setIsOpen] = React.useState(false);
    const [openResumeLink, setOpenResumeLink] = React.useState(false);
  
    const onChange = (e) => {
      setValue(e.target.value);
    };
  
    const onBlur = () => {
      updateData(index, id, value);
    };
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return initialValue ? (
      <div>
        <button onClick={() => setIsOpen(true)}>Edit</button>
        <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
          <ResumeLinkModal
            rowValue={value}
            setOpenModal={setIsOpen}
            updateResumeLink={updateData}
            row={index}
            column={id}
          />
        </Modal>
        <a href={value} target="_blank" rel="noopener noreferrer">
          Open
        </a>
      </div>
    ) : (
      <div>
        <input value={value || ""} onChange={onChange} onBlur={onBlur} />
      </div>
    );
  };