import React from 'react';

export function SubStatus({ selectedKey, combinedOptions, profDetail, updateProfileDetail, pageType }) {
  const selectedStatusKey = `${pageType.toLowerCase()}_status`;
  const selectedSubStatusKey = `${pageType.toLowerCase()}_sub_status`;

  const handleStatusChange = (e) => {
    // Call both updateProfileDetail and handleKeyChange when the status changes
    updateProfileDetail(e, pageType.toLowerCase());
  };

  return (
    <>
      <div className="col-3 text-center">
        <label>Sub-Status</label>
      </div>
      <div className="col-3">
        <select
          name="sub_status"
          value={profDetail[selectedSubStatusKey] || ""}
          className="form-control"
          onChange={handleStatusChange} // Call handleStatusChange instead of updateProfileDetail
        >
          <option disabled={true} value="">
            Select
          </option>
          {combinedOptions[pageType].subStatus[selectedKey].map((subStatusOption, index) => (
            <option key={index} value={subStatusOption.toLowerCase()}>
              {subStatusOption}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
