import React from "react";

export const EditableCellDropCollegePreference = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
      updateData(index, id, e.target.value);
    };
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <select value={value || "Any"} onChange={onChange}>
        <option>Premium</option>
        <option>Premium + Tier 1</option>
        <option>Any</option>
      </select>
    );
  };