import React from "react";

export function JobPreference({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Job Preference</label>
      </div>
      <div className="col-8">
        <select
          name="job_prefer_type"
          value={profDetail.job_prefer_type || ""}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        >
          <option disabled={true} value="">
            Select
          </option>
          <option value="hybrid">Hybrid</option>
          <option value="remote">Remote</option>
          <option value="onsite">Onsite</option>
        </select>
      </div>
    </>
  );
}
