import React from "react";
import Modal from "../components/modal/Modal";
import { Comments } from "../profile_details/comments";

export const EditableCellRemarks = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
  updateData,
  addRemark,
}) => {
  const [value, setValue] = React.useState(JSON.stringify(initialValue));
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div style={{ display: "flex" }}>
      <input readOnly value={value || ""} />
      <button onClick={() => setIsOpen(true)}>Edit</button>
      <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
        <Comments
          data={data[index]} // Pass your rowValue here
        />
      </Modal>
    </div>
  );
};
