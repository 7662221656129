import React from "react";

export const EditableCellDropCandidateStatus = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
      updateData(index, id, e.target.value);
    };
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <select value={value || "Others"} onChange={onChange}>
        <option>New</option>
        <option>Reminder 1 </option>
        <option>Reminder 2 </option>
        <option>Reminder 3 </option>
        <option>Asked to reshare</option>
        <option>Shared resume</option>
        <option>Shared number</option>
        <option>Not able to reach out</option>
        <option>Others</option>
      </select>
    );
  };