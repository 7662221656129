import React from "react";
import { DashAutoComplete } from "../components/autocomplete";

export const EditableCellSource = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
  updateData,
}) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <DashAutoComplete
      tableName="source"
      index={index}
      id={id}
      updateData={updateData}
      intialValue={value}
      selectedValue={data[index]["source"]}
    />
  );
};
