import React from "react";

export function HoldingCTC({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Holding CTC</label>
      </div>
      <div className="col-8">
        <input
          type="text"
          name="holding_ctc"
          value={profDetail.holding_ctc}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        />
      </div>
    </>
  );
}
