import React from "react";
import { CustomeDateformat } from "../columns/customDateFormat";
import { DateRangeFilter } from "../filters/dateRangeFilter";
import { dateBetweenFilterFn } from "./Table";
import { EditableCellName } from "../columns/editableCellName";
import { EditableCellEmail } from "../columns/editableCellEmail";
import { EditableCellExp } from "../columns/editableCellExp";
import { EditableCellDropCompany } from "../columns/editableCellDropCompany";
import { SelectColumnFilter } from "../filters/selectColumnFilter";
import { EditableCellDropCompanytype } from "../columns/EditableCellDropCompanytype";
import { NumberRangeColumnFilter } from "../filters/numberRangeColumnFilter";
import { EditableCellRange } from "../columns/editableCellRange";
import { EditableCell } from "../columns/EditableCell";
import { EditableCellSkill } from "../columns/editableCellSkill";
import { EditableCellJobType } from "../columns/editableCellJobType";
import { EditableCellDropLocation } from "../columns/editableCellDropLocation";
import { EditableCellDropNotice } from "../columns/editableCellDropNotice";
import { EditableCellRangeNotice } from "../columns/editableCellRangeNotice";
import { SelectColumnFilterHoldingOffer } from "../filters/selectColumnFilterHoldingOffer";
import { EditableCellHoldingOffer } from "../columns/editableCellHoldingOffer";
import { EditableCellDropCollege } from "../columns/editableCellDropCollege";
import { EditableCellDropCandidateStatus } from "../columns/editableCellDropCandidateStatus"; 
import { EditableCellDropExternalSubStatus } from "../columns/editableCellDropExternalSubStatus"; 
import { ResumeDowmload } from "../columns/resumeDownload";
import { EditableCellDropExternalStatus } from "../columns/editableCellDropExternalStatus"; 

export const useExternalColumns = () => {
  return React.useMemo(
    () =>[
    {
      Header: "SelectedTimestamp",
      accessor: "shortlist_date",
      sticky: "left",
      Cell: CustomeDateformat,
      Filter: DateRangeFilter,
      filter: dateBetweenFilterFn,
    },
    {
      Header: "Name",
      accessor: "name",
      // maxWidth: "10",
      sticky: "left",
      Cell: EditableCellName,
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      sticky: "left",
    },
    {
      Header: "Alternate Mobile",
      accessor: "alternate_mobile",
    },
    {
      Header: "Email Id",
      accessor: "email",
      Cell: EditableCellEmail,
    },

    {
      Header: "Status",
      accessor: "external_status",
      Cell: EditableCellDropExternalStatus,
    },
    {
      Header: "Sub-Status",
      accessor: "external_sub_status",
      Cell: EditableCellDropExternalSubStatus,
    },

    {
      Header: "Total Work Experience",
      accessor: "total_exp",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: EditableCellExp,
    },
    {
      Header: "Current Company",
      accessor: "company_name",
      Cell: EditableCellDropCompany,
    },
    {
      Header: "Product/Non-product",
      accessor: "company_type",
      Filter: SelectColumnFilter,
      Cell: EditableCellDropCompanytype,
    },
    {
      Header: "Current CTC",
      accessor: "current_ctc_float",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: EditableCellRange,
    },
    {
      Header: "Expected CTC",
      accessor: "min_expected_ctc_float",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: EditableCellRange,
    },
    {
      Header: "Preferred job designation",
      accessor: "designation",
      Cell: EditableCell,
    },
    {
      Header: "Skill * Experience",
      accessor: "skill",
      Cell: EditableCellSkill,
    },
    {
      Header: "Job Preference Type",
      accessor: "job_prefer_type",
      Filter: SelectColumnFilter,
      Cell: EditableCellJobType,
    },
    {
      Header: "Job Preference Location",
      accessor: "job_prefer_location",
      Cell: EditableCellDropLocation,
    },

    {
      Header: "Current Location",
      accessor: "current_location",
      Cell: EditableCellDropLocation,
    },

    {
      Header: "Serving Notice Status",
      accessor: "notice_period_status",
      Filter: SelectColumnFilter,
      Cell: EditableCellDropNotice,
    },
    {
      Header: "Serving Notice Days",
      accessor: "notice_period_days",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: EditableCellRangeNotice,
    },
    {
      Header: "Last Working Day",
      accessor: "last_working_day",
      Cell: CustomeDateformat,
      Filter: DateRangeFilter,
      filter: dateBetweenFilterFn,
    },
    {
      Header: "Holding offer",
      accessor: "holding_offer",
      Filter: SelectColumnFilterHoldingOffer,
      Cell: EditableCellHoldingOffer,
    },
    {
      Header: "Holding CTC ",
      accessor: "holding_ctc",
      Filter: NumberRangeColumnFilter,
      filter: "between",
      Cell: EditableCellRange,
    },
    {
      Header: "College Name",
      accessor: "college_name",
      Cell: EditableCellDropCollege,
    },
    {
      Header: "Candidate status",
      accessor: "candidate_status",
      Filter: SelectColumnFilter,
      Cell: EditableCellDropCandidateStatus,
    },
    {
      Header: "Days since candidate was added to DB",
      accessor: "days_candidate_added_to_db",
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell: EditableCell,
    },
    {
      Header: "Resume",
      accessor: "resume_link",
      Filter: "",
      Cell: ResumeDowmload,
    },
  ]
  );
};
