import React from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;

export function Resume({openFileChooser,hiddenFileInput,profDetail,changeHandler}){
  const downloadResume = async(data) => {
    console.log(data);
    const response = await fetch(`${BASEURL}resume/`+data.candidate_random_id);
    const blob = await response.blob();
     // Create a URL for the PDF blob
     const url = URL.createObjectURL(blob);
  
     // Open a new tab with the PDF
     const newTab = window.open(url, '_blank');
     newTab.document.title = 'Resume';
     newTab.document.body.appendChild(newTab.document.createElement('iframe')).src = url;
    //const newTab = window.open(`https://docs.google.com/viewer?url=${encodeURIComponent(url)}`, '_blank');
    // window.open(url, "_blank");
    if(newTab){
      newTab.focus();
    }else{
      console.log("opening new tab is blocked by browser")
    }
    //const id = data.candidate_id;
    //window.open(`/pdf-viewer/${id}`, '_blank');
  };

    return(
        <>
         {profDetail.resume_link !== null ? (
                  <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => downloadResume(profDetail)}
                  >
                    Resume Download
                  </button>
                  <div>
                  <input
                      type="file"
                      name={"UserResume"}
                      id={"userresume"}
                      title="UserResume"
                      accept=".pdf"
                      ref={hiddenFileInput}
                      onChange={changeHandler}
                      style={{ display: "none" }}
                    />
                  <button
                    className="btn btn-primary"
                    style={{marginTop:"5px"}}
                    onClick={openFileChooser}
                    //profDetails need to be given in onClick
                  >
                    Resume Update
                  </button>
                  </div>
                  </div>
                ) : (
                  <div>
                    <input
                      type="file"
                      name={"UserResume"}
                      id={"userresume"}
                      title="UserResume"
                      accept=".pdf"
                      ref={hiddenFileInput}
                      onChange={changeHandler}
                      style={{ display: "none" }}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={openFileChooser}
                    >
                      Resume Upload
                    </button>
                  </div>
                )}
        </>
    )
}