import React from "react";
import "./new-jd.css";
import CreatableDropCompnay from './creatable-company'



function NewJD({ InsertJD, setToggle }) {

  const [valueCampany, setValueCampany] = React.useState('')
  const [valueDesignation, setValueDesignation] = React.useState('')

  const onSave = ()=>{
    InsertJD(valueCampany, valueDesignation)
    setToggle(false)
  }

  return (
    <div  className="background" >
      <div className="container-jd">
        <div className="titleCloseBtn">
            <h4>Add New JD</h4>
          <button onClick={()=>{setToggle(false)}}>X</button>
        </div>
        <div>Company Name</div>
        <CreatableDropCompnay setValue = {setValueCampany}/>
        <div>Designation</div>
        <input type ="text" style={{margin:"2px",border:"1px solid grey", borderRadius:"5px", height:"40px"}} onChange = {(e)=>{setValueDesignation(e.target.value)}}></input>
        <div className="footer">
          <button
            onClick={()=>{setToggle(false)}}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button onClick = {onSave}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default NewJD;