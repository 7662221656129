import React from 'react';

export function Notes({profDetail,updateProfileDetail}){
    return(
        <>
        <div className="col-2 text-center">
                <label>Remark</label>
              </div>
              <div className="col-3">
                <textarea
                  rows="3"
                  name="remark"
                  className="form-control"
                  onChange={(e) => updateProfileDetail(e)}
                  value={profDetail.remark}
                />
              </div>
        </>
    )
}