import React from "react";
import { CustomeDateformat } from "../columns/customDateFormat";
import { DateRangeFilter } from "../filters/dateRangeFilter";
import { dateBetweenFilterFn, statusFilterFn } from "./Table";
import { EditableCellName } from "../columns/editableCellName";
import { EditableCellEmail } from "../columns/editableCellEmail";
import { DefaultColumnFilterStatus } from "../filters/defaultColumnFilterStatus";
import { EditableCellDropInternalStatus } from "../columns/editableCellDropInternalStatus";
import { EditableCellDropInternalSubStatus } from "../columns/editableCellDropInternalSubStatus";
import { NumberRangeColumnFilterInternal } from "../filters/numberRangeColumnFilterInternal";
import { EditableCellExp } from "../columns/editableCellExp";
import { EditableCellDropCompany } from "../columns/editableCellDropCompany";
import { SelectColumnFilter } from "../filters/selectColumnFilter";
import { EditableCellDropCompanytype } from "../columns/EditableCellDropCompanytype";
import { NumberRangeColumnFilter } from "../filters/numberRangeColumnFilter";
import { EditableCellRange } from "../columns/editableCellRange";
import { EditableCell } from "../columns/EditableCell";
import { ColumnFilterForInternalExp } from "../filters/columnFilterForInternalExp";
import { EditableCellSkill } from "../columns/editableCellSkill";
import { SelectColumnFilterInternal } from "../filters/selectColumnFilterInternal";
import { EditableCellJobType } from "../columns/editableCellJobType";
import { EditableCellDropLocation } from "../columns/editableCellDropLocation";
import { ColumnFilterForInternal } from "../filters/columnFilterForInternal";
import { EditableCellDropNotice } from "../columns/editableCellDropNotice";
import { EditableCellRangeNotice } from "../columns/editableCellRangeNotice";
import { SelectColumnFilterHoldingOffer } from "../filters/selectColumnFilterHoldingOffer";
import { EditableCellHoldingOffer } from "../columns/editableCellHoldingOffer";
import { EditableCellDropCollege } from "../columns/editableCellDropCollege";
import { EditableCellRemarks } from "../columns/editableCellRemarks";
import { SendMailTemplate } from "../columns/sendMailTemplate";
import { WhatsApp } from "../columns/whatsapp";
import { ResumeDowmload } from "../columns/resumeDownload";
import { EditableCellSource } from "../columns/editableCellSource";
export const useColumns = () => {
  return React.useMemo(
    () => [
      {
        Header: "SelectedTimestamp",
        accessor: "shortlist_date",
        Cell: CustomeDateformat,
        sticky: "left",
        Filter: DateRangeFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Name",
        accessor: "name",
        // maxWidth: "10",
        sticky: "left",
        Cell: EditableCellName,
      },

      {
        Header: "Mobile",
        accessor: "mobile",
        sticky: "left",
      },

      {
        Header: "Alternate Mobile",
        accessor: "alternate_mobile",
      },

      {
        Header: "Email Id",
        accessor: "email",
        Cell: EditableCellEmail,
      },

      {
        Header: "Status",
        accessor: "internal_status",
        maxWidth: "6",
        Filter: DefaultColumnFilterStatus,
        Cell: EditableCellDropInternalStatus,
        filter: statusFilterFn,
      },

      {
        Header: "Sub-Status",
        accessor: "internal_sub_status",
        maxWidth: "10",
        Cell: EditableCellDropInternalSubStatus,
      },

      {
        Header: "Total Work Experience",
        accessor: "total_exp",
        Filter: NumberRangeColumnFilterInternal,
        filter: "between",
        Cell: EditableCellExp,
      },

      {
        Header: "Current Company",
        accessor: "company_name",
        Cell: EditableCellDropCompany,
      },
      {
        Header: "Product/Non-product",
        accessor: "company_type",
        Filter: SelectColumnFilter,
        Cell: EditableCellDropCompanytype,
      },
      {
        Header: "Current CTC",
        accessor: "current_ctc_float",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellRange,
      },

      {
        Header: "Expected CTC",
        accessor: "min_expected_ctc_float",
        Filter: NumberRangeColumnFilterInternal,
        filter: "between",
        Cell: EditableCellRange,
      },

      {
        Header: "Preferred job designation",
        accessor: "designation",
        Cell: EditableCell,
      },

      {
        Header: "Skill * Experience",
        accessor: "skill",
        Filter: ColumnFilterForInternalExp,
        Cell: EditableCellSkill,
      },

      {
        Header: "Job Preference Type",
        accessor: "job_prefer_type",
        Filter: SelectColumnFilterInternal,
        Cell: EditableCellJobType,
      },

      {
        Header: "Job Preference Location",
        accessor: "job_prefer_location",
        Cell: EditableCellDropLocation,
        maxWidth: 10,
        Filter: ColumnFilterForInternal,
      },

      {
        Header: "Current Location",
        accessor: "current_location",
        Cell: EditableCellDropLocation,
        maxWidth: 10,
      },

      {
        Header: "Serving Notice Status",
        accessor: "notice_period_status",
        Filter: SelectColumnFilter,
        Cell: EditableCellDropNotice,
      },

      {
        Header: "Serving Notice Days",
        accessor: "notice_period_days",
        Filter: NumberRangeColumnFilterInternal,
        filter: "between",
        Cell: EditableCellRangeNotice,
      },
      {
        Header: "Last Working Day",
        accessor: "last_working_day",
        Cell: CustomeDateformat,
        Filter: DateRangeFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Holding offer",
        accessor: "holding_offer",
        Filter: SelectColumnFilterHoldingOffer,
        Cell: EditableCellHoldingOffer,
      },

      {
        Header: "Holding CTC ",
        accessor: "holding_ctc",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellRange,
      },

      {
        Header: "College Name",
        accessor: "college_name",
        Cell: EditableCellDropCollege,
        Filter: ColumnFilterForInternal,
      },
      {
        Header: "Remark",
        accessor: "remark",
        Cell: EditableCellRemarks,
        Filter: "",
      },
      {
        Header: "Send JD",
        accessor: "jd_sent",
        maxWidth: 5,
        Cell: SendMailTemplate,
        Filter: "",
      },

      {
        Header: "WhatsApp",
        Cell: WhatsApp,
      },

      {
        Header: "Resume",
        accessor: "resume_link",
        Filter: "",
        Cell: ResumeDowmload,
      },
      {
        Header: "Source",
        accessor: "source",
        Cell: EditableCellSource,
      },
      {
        Header: "Days since candidate was added to DB",
        accessor: "days_candidate_added_to_db",
      },
    ],
    []
  );
};
