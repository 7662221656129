import React from "react";

export function SelectColumnFilterInternal({
  column: { filterValue, setFilter, preFilteredRows, id },
  JdCompanyName,
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <div style={{ width: "120px" }}>
      <select
        style={{ margin: "3px" }}
        value={filterValue}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => {
          if (option) {
            return (
              <option key={i} value={option}>
                {option}
              </option>
            );
          }
        })}
      </select>
      <div style={{ border: "1px solid green" }}>
        <span style={{ color: "red", fontSize: "100%", paddingBottom: "5px" }}>
          ReQ:{" "}
        </span>
        <span>{JdCompanyName.job_site}</span>
      </div>
    </div>
  );
}
