import React from "react";
import { insertIntoTable, loadTableData } from "../api";

export const EditableCellJobType = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
}) => {
  const [value, setValue] = React.useState("");
  const [usersData, setUsersData] = React.useState([]);
  const [userValue,setUserValue]=React.useState("");

  const onChange = async (e) => {
    setValue(e.target.value);
    if(id === 'job_site'){
      updateData(index, id, e.target.value);
    }else if (id === 'users'){
      updateData(index, "assigned_kam", e.target.value);
    }
    
  };

  const fetchUsers = async () => {
    var tableName = "public.user";
    const { result } = await loadTableData(tableName, {});
    console.log("Users", result);
    setUsersData(result.map((item) => item.email));
  };

  React.useEffect(() => {
    console.log("Id", id);
    if (id === "job_site") {
      setValue(initialValue);
    } else if (id === "users") {
      fetchUsers();
    }
  }, [initialValue]);

  return (
    <>
      {id === "job_site" ? (
        <select value={value} onChange={onChange}>
          <option disabled value="">
            Select
          </option>
          <option value="Hybrid">Hybrid</option>
          <option value="Remote">Remote</option>
          <option value="Onsite">Onsite</option>
        </select>
      ) : id === "users" ? (
        <select value={value} onChange={onChange}>
          <option disabled value="">
            Select User
          </option>
          {usersData.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      ) : null}
    </>
  );
};
