import React from "react";
import Modal from "../components/modal/Modal";
import { Email } from "../modals";

export const EditableCellEmail = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const updatedEmail = (email) => {
    updateData(index, id, email);
  };

  return (
    <div style={{ display: "flex" }}>
      <input readOnly value={value || ""} />
      <button onClick={() => setIsOpen(true)}>Edit</button>
      <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
        <Email
          rowValue={value}
          setOpenModal={setIsOpen}
          updatedEmail={updatedEmail}
        />
      </Modal>
    </div>
  );
};
