import React from "react";

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id]) {
        options.add(row.values[id]);
      }
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      style={{ margin: "3px" }}
      value={filterValue}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => {
        if (option) {
          return (
            <option key={i} value={option}>
              {option}
            </option>
          );
        }
      })}
    </select>
  );
}
