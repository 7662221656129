import React, { useState } from "react";
import Modal from "../components/modal/Modal";
import Table from "../tableView/Table";
import { POCModal } from "../modals/pocModal";

const EditablePOC=({
  value: initialValue,
  row: { index },
  column: { id },
  data,
})=> {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action,setAction]=useState('');
  const [newPOC, setNewPOC] = useState([{
    name: "", // Initial value for skill type
    designation: "", // Initial value for keyword
    email: "",
    mobile:"" // Initial value for years of experience
  }]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div>
      <button onClick={openModal}>Add POC</button>
      <Modal handleClose={closeModal} isOpen={isModalOpen}>
        <POCModal onClose={closeModal} newPOC={newPOC} setNewPOC={setNewPOC} rowValue={data[index]} action={action} setAction={setAction} />
      </Modal>
    </div>
  );
}

export default EditablePOC;