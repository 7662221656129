import React from "react";
import "./ToggleSwitch.css"
import cx from "classnames"

const Switch = ( {rounded = false, isToggle, change}) => {
    const sliderCX = cx("slider", {
        'rounded': rounded
    });
    return (<label className = "switch">
        <input type = "checkbox" checked = {isToggle} onChange = {change}/>
        <span className = {sliderCX}/>
    </label>)
}

export default Switch;