import React from "react";
import { DatePicker } from "rsuite";

export function LWD({LWDDate,updateLWD}) {
  return (
    <>
      <div className="col-4">
        <label>LWD</label>
      </div>
      <div className="col-8">
        <DatePicker oneTap value={LWDDate} onChange={updateLWD} />
      </div>
    </>
  );
}
