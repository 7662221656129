import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import "react-toastify/dist/ReactToastify.css";
import "./header.css";
import Modal from "./components/modal/Modal";
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const BASEURL = process.env.REACT_APP_BASE_URL;

const notify1 = (notification) =>
  toast.error(notification, { position: toast.POSITION.TOP_CENTER });

const notify2 = (notification) =>
  toast.success(notification, { position: toast.POSITION.TOP_CENTER });

const UploadFile = ({ jd, source, url, setModalOpen, setUploadData }) => {
  const [selectedFile, setSelectedFile] = React.useState();
  const [loader, showLoader] = React.useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = async () => {
    if (!selectedFile) {
      toast.error("Please upload file", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    showLoader(true);
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("job_id", jd.toString());
    const uploadURL = `${BASEURL}api/getfile/` + url;

    const requestOptions = {
      method: "POST",
      body: formdata,
    };

    try {
      const response = await fetch(uploadURL, requestOptions);
      if (response?.status !== 200) {
        showLoader(false);
        throw new Error("Server responds with error!");
      }
      showLoader(false);
      notify2("Uploaded Successfully");
      //setModalOpen(true);
      //setUploadData(response);
      //console.log(response.text());
      //return response.text();
    } catch (err) {
      showLoader(false);
      notify1(String(err));
      console.log(err);
    }
  };
  const htmlId = source + "file";

  return (
    <div style={{ display: "block" }}>
      <div>
        <label
          htmlFor={htmlId}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid grey",
            borderRadius: "0px",
            marginTop: "3px",
            color: "white",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "13px",
            lineHeight: "100%",
            width: "140px",
            height: "28px",
          }}
        >
          Choose Zip File
        </label>
        <input
          type="file"
          name={htmlId}
          id={htmlId}
          title="  "
          onChange={changeHandler}
          hidden
        />
      </div>
      <div>
        <button
          style={{
            backgroundColor: "rgb(23,229,195)",
            textDecoration: "none",
            border: "none",
            marginTop: "3px",
            width: "140px",
            height: "28px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "13px",
            lineHeight: "100%",
          }}
          onClick={handleSubmission}
        >
          {source} {loader ? <Spinner size="sm" animation="border" /> : null}
        </button>
      </div>
    </div>
  );
};

const covertToyearMonths = (exp) => {
  const numberAsInt = parseInt(exp, 10);
  const quotient = Math.floor(numberAsInt / 12);
  const remainder = numberAsInt % 12;
  const displayValue =
    quotient +
    (quotient === 1 ? " Year " : " Years ") +
    remainder +
    (remainder === 1 ? " Month " : " Months ");
  return displayValue;
};

const getDateFormate = (timeStamp) => {
  const tStamp = new Date(timeStamp);
  const dateFormat =
    tStamp.getDate() +
    "-" +
    (tStamp.getMonth() + 1) +
    "-" +
    tStamp.getFullYear();
  return dateFormat;
};

const Header = ({
  link,
  linktype,
  jd,
  data,
  JdCompanyName,
  setToggle,
  setToogleCandidates,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [uploadData, setUploadData] = React.useState();
  const navigate = useNavigate();
  const userType = window.sessionStorage.getItem("UserType");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;
  let formatedData = "";
  if (data) {
    formatedData = data.map((item) => {
      return {
        ...item,
        ...{
          shortlist_date: getDateFormate(item.shortlist_date),
          total_exp: covertToyearMonths(item.total_exp),
          mobile: item.mobile.slice(-10),
          resume: `${BASEURL}resume/${item.candidate_random_id}`,
          skill: item?.skill
            ? JSON.stringify(item.skill).replaceAll(",", " ")
            : "",
        },
      };
    });
  }

  const headers = [
    { label: "Date", key: "shortlist_date" },
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Email", key: "email" },
    {
      label: "Status",
      key: linktype === "EXTERNAL" ? "external_status" : "internal_status",
    },
    {
      label: "Sub status",
      key:
        linktype === "EXTERNAL" ? "external_sub_status" : "internal_sub_status",
    },
    { label: "Total Work Experience", key: "total_exp" },
    { label: "Current Company", key: "company_name" },
    { label: "Current CTC", key: "current_ctc_float" },
    { label: "Expected CTC", key: "min_expected_ctc_float" },
    { label: "Current Designation", key: "designation" },
    { label: "Job Prefrecne Location", key: "job_prefer_location" },
    { label: "Notice Period", key: "notice_period_days" },
    { label: "Holding Offer", key: "holding_offer" },
    { label: "Holding CTC", key: "holding_ctc" },
    { label: "College Name", key: "college_name" },
    { label: "Remarks", key: "remark" },
    { label: "Current Location", key: "current_location" },
    { label: "Preferred Location", key: "job_prefer_location" },
    { label: "Resume", key: "resume" },
  ];

  const candidateHeaders = [
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Email", key: "email" },
    { label: "Total Work Experience", key: "total_exp" },
    { label: "Current Company", key: "company.name" },
    { label: "Product/Non-product", key: "company.type" },
    { label: "Current CTC", key: "current_ctc_float" },
    { label: "Expected CTC", key: "min_expected_ctc_float" },
    { label: "Preferred job designation", key: "designation" },
    { label: "Skill", key: "skill" },
    { label: "Job Preference Type", key: "job_prefer_type" },
    { label: "Job Preference Location", key: "job_prefer_location" },
    { label: "Serving Notice Status", key: "notice_period_status" },
    { label: "Serving Notice Days", key: "notice_period_days" },
    { label: "Last Working Day", key: "last_working_day" },
    { label: "Holding offer", key: "holding_offer" },
    { label: "Holding CTC", key: "holding_ctc" },
    { label: "College Name - Highest Degree", key: "college.name" },
    { label: "Candidate status", key: "candidate_status" },
    { label: "Source", key: "source" },
    {
      label: "Days since candidate was added to DB",
      key: "days_candidate_added_to_db",
    },
    { label: "Resume", key: "resume" },
    { label: "Remark", key: "remark" },
  ];

  const LogOut = () => {
    console.log("logging out");
    Cookies.remove("gSign");
    window.sessionStorage.clear();
    navigate("/");
  };

  const handleSettings = ()=>{
    navigate("/settings")
  }

  return (
    <div className="header">
      {linktype === "PDF" ? (
        <div className="brand">
          <Link
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            to={"/"}
          >
            <img src={require("./images/DashLogo.PNG")} alt="Logo" />
          </Link>
        </div>
      ) : (
        <>
          <div className="brand">
            <Link
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              to={"/"}
            >
              <img src={require("./images/DashLogo.PNG")} alt="Logo"></img>
            </Link>
          </div>
          <div className="jd">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(linktype === "JD" || linktype === "EXTERNAL") &&
              userType !== "true" ? (
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: "21px",
                    textDecoration: "none",
                    marginLeft: "60px",
                    border: "1px",
                    backgroundColor: "rgb(0,0,25)",
                    color: "white",
                    width: "111px",
                    height: "16px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "100%",
                  }}
                  to={"/candidates"}
                >
                  ALL CANDIDATES
                </Link>
              ) : (
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                    textDecoration: "none",
                    marginLeft: "60px",
                    paddingRight: "21px",
                    border: "1px",
                    backgroundColor: "rgb(0,0,25)",
                    color: "white",
                    width: "111px",
                    height: "16px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "100%",
                  }}
                  to={"/jd"}
                >
                  JD
                </Link>
              )}

              {linktype === "JD" && userType !== "true" ? (
                <button
                  style={{
                    padding: "16px 20px",
                    marginLeft: "55px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "100%",
                    width: "159px",
                    height: "48px",
                    fontSize: "16px",
                    backgroundColor: "#17E5C3",
                  }}
                  onClick={() => {
                    setToggle(true);
                  }}
                >
                  + Add New JD
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {link ? (
            <>
              {link !== "candidates" ? (
                <div className="links">
                  <Link
                    style={{
                      padding: "16px 20px",
                      textDecoration: "none",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "16px",
                      fontStyle: "normal",
                      lineHeight: "100%",
                      width: "159px",
                      height: "48px",
                      color: "white",
                    }}
                    to={link}
                  >
                    {userType !== "true"
                      ? linktype === "EXTERNAL"
                        ? "INTERNAL"
                        : "EXTERNAL"
                      : ""}
                  </Link>
                </div>
              ) : null}
              {linktype === "INTERNAL" ? (
                <>
                  <div className="upload">
                    <UploadFile
                      jd={jd}
                      source={"Upload InstaHyre"}
                      url={"instahyrefile"}
                      setModalOpen={setModalOpen}
                      setUploadData={setUploadData}
                    />{" "}
                    <Modal
                      handleClose={() => setModalOpen(false)}
                      isOpen={modalOpen}
                    ></Modal>
                  </div>
                  <div className="upload">
                    <UploadFile
                      jd={jd}
                      source={"Upload Naukri"}
                      url={"naukrifile"}
                    />{" "}
                  </div>
                  <div className="upload">
                    <UploadFile
                      jd={jd}
                      source={"Upload Generic"}
                      url={"genericfile"}
                    />{" "}
                  </div>
                </>
              ) : (
                ""
              )}
              {linktype === "INTERNAL" ? (
                <button
                  className="btncss"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    border: "1px",
                    borderRadius: "0px",
                    marginLeft: "20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontStyle: "normal",
                    width: "200px",
                    height: "48px",
                    backgroundColor: "#17E5C3",
                    lineHeight: "100%",
                  }}
                  onClick={() => {
                    setToogleCandidates(true);
                  }}
                >
                  Request Candidate
                </button>
              ) : (
                ""
              )}
              <div className="btncss">
                <CSVLink
                  filename={
                    link === "candidates"
                      ? "AllCandidate_Sheet.csv"
                      : (linktype === "EXTERNAL" ? "External" : "Internal") +
                        `_Sheet_${
                          JdCompanyName ? JdCompanyName.company_name : ""
                        }_${JdCompanyName ? JdCompanyName.designation : ""}.csv`
                  }
                  data={
                    link === "candidates"
                      ? data
                        ? data
                        : ""
                      : data
                      ? formatedData
                      : ""
                  }
                  headers={link === "candidates" ? candidateHeaders : headers}
                  className="btn btn-primary"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    border: "1px",
                    borderRadius: "0px",
                    padding: "16px 20px",
                    marginLeft: "40px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "100%",
                    width: "159px",
                    height: "48px",
                    backgroundColor: "#17E5C3",
                  }}
                >
                  {" "}
                  Download CSV
                </CSVLink>
              </div>
              {linktype !== "CANDIDATE" && (
                <div className="company">
                  <div>
                    <div style={{ color: "white" }}>Company: </div>
                    <div style={{ color: "white" }}>
                      {JdCompanyName ? JdCompanyName.company_name : ""}
                    </div>
                  </div>
                  <div>
                    <div style={{ color: "white" }}>Profile: </div>
                    <div style={{ color: "white" }}>
                      {JdCompanyName ? JdCompanyName.designation : ""}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          {linktype === "JD" ? (
            <Link
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "21px",
                textDecoration: "none",
                marginLeft: "60px",
                border: "1px",
                backgroundColor: "rgb(0,0,25)",
                color: "white",
                width: "111px",
                height: "16px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "100%",
              }}
              to={"/compensation"}
            >
              Compensation Meter
            </Link>
          ) : (
            ""
          )}
          <div>
            <IconButton
              color="inherit"
              onClick={handleClick}
              aria-describedby={id}
              style={{position:"fixed",top:20,right:10,borderRadius:"50%",backgroundColor:"#EBEBEB",height:"50px",width:"50px"}}
            >
              <PersonOutlineIcon/>

            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ padding: "16px",display:"flex",flexDirection:"column" }}>
                <Button onClick={handleSettings}>Settings</Button>
                <Button onClick={LogOut}>Logout</Button>
              </div>
            </Popover>
          </div>
        </>
      )}
    </div>
  );
};
export default Header;
