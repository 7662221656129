export const CustomeDateformat = ({
    value: initialValue,
    row: { index },
    column: { id },
  }) => {
    const timeStamp =
      initialValue instanceof Date ? initialValue : new Date(initialValue);
    var dateFormat =
      timeStamp.getDate() +
      "-" +
      (timeStamp.getMonth() + 1) +
      "-" +
      timeStamp.getFullYear();
    return <span>{initialValue ? dateFormat : ""}</span>;
  };