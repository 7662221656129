
import './footer.css'
const Footer = () => {
    const year = new Date().getFullYear();
  
    return <footer>{`Copyright © DashHire ${year}`}
    <div>
    <span><a href="https://docs.google.com/document/d/e/2PACX-1vRjplJnVse9xP4GcZFoNqgOFyvdcx_I1MRNxkaZabcr784nl106f9JiJ1GiajcIXiIBZh_Sq-mni5HJ/pub" target="_blank" rel="noopener noreferrer">Terms</a></span>
    <span><a href="https://docs.google.com/document/d/e/2PACX-1vQ1WUffVFoBk5KATIuCpLmX7doBb_U-pYt5ggG-5zHbv_3LUtI2GNG-bAo28rAxkySwzPdbjwwZIbq1/pub" target="_blank" rel="noopener noreferrer">Privacy</a></span>
    </div>
    
    </footer>;
  };
  
  export default Footer;