import React from "react";
import { locationCities } from "../utility";
import { Multiselect } from "multiselect-react-dropdown";

export const EditableCellDropLocation = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      const cityStr = e.map((city) => city.value).toString();
      setValue(cityStr);
      updateData(index, id, cityStr);
    };
  
    const splitValue = (value) => {
      var items = value ? value.split(",") : [];
      return items.map((city) => ({ value: city, label: city }));
    };
  
    const cityOptions = locationCities();
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <Multiselect
        style={{ minHeight: 35, width: 300 }}
        selectedValues={splitValue(value)}
        options={cityOptions}
        groupBy="cat"
        displayValue="value"
        onSelect={onChange}
        onRemove={onChange}
      />
    );
  };