import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, Navigate } from "react-router-dom";
import Footer from "./footer";
import { makeApiCallFull } from "./api";
const BASEURL = process.env.REACT_APP_BASE_URL ||'https://app.pragti.in/internal-api-stage/';
const _ = require("lodash");

const HomePage = () => {
  const navigate = useNavigate();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '897467663642-gceeonvokfvtmojbv06kj20ipf23dac2.apps.googleusercontent.com';


  async function handleCredentialResponse(response) {
    console.log("running handleCredentialResponse")
    console.log("response",response)
    Cookies.remove("gSign");
    Cookies.set("gSign", response.credential, { path: "/", expires: 30 });

    //make api call to get usertype
    const config = {
      method: "GET",
      url: `${BASEURL}user/type`,
      headers: {
        Authorization: response.credential,
      },
      mode: "no-cors",
    };
    const checkUserType = await makeApiCallFull(config);
    if (_.size(checkUserType) > 0) {
      if (checkUserType.status === 200) {
        const { id,is_external } = checkUserType.data;
        window.sessionStorage.setItem('UserType', is_external);
        window.sessionStorage.setItem('UserID', id);
        navigate("/jd");
      } else if (checkUserType.status === 401) {
        navigate("/welcome");
      }
    }
  }

  useEffect(() => {
    /*global google*/
    // window.onload = function () {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse
        });
    
        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
          theme: "filled_blue",
          size: "large",
        });
      };

    document.body.appendChild(script);

    return () => {
      // Clean up the script tag
      document.body.removeChild(script);
    };
    // }
  }, []);

  var user = Cookies.get("gSign", { path: "/" });
  if (user) {
    return <Navigate to={"/jd"} replace />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div className="header">
        <div className="brand">
          <img src={require("./images/Dash.png")} alt="Logo"></img>
        </div>
      </div>
      <div id="signInDiv"></div>
      <Footer />
    </div>
  );
};

export default HomePage;
