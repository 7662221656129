import React from "react";
import Switch from "./ToggleSwitch";

export const EditableCellToggle = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
    data
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const [isToggle, setIsToggle] = React.useState(value);
    const onChange = (e) => {
      const valueToSet = !isToggle;
      setValue(valueToSet);
      setIsToggle(!isToggle);
      updateData(index, id, valueToSet);
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <Switch rounded={true} isToggle={isToggle} change={onChange}>
        {" "}
      </Switch>
    );
  };