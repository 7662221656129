import React, { useState } from "react";
import { insertIntoTable, updateTableData } from "../api";

export function SkillModal({
  onClose,
  action,
  newSkill,
  setNewSkill,
  setAction,
  rowValue,
}) {
  console.log("RowValue", rowValue);
  const [newRow, setNewRow] = useState({
    type: "", // Initial value for skill type
    keyword: "", // Initial value for keyword
    years: "", // Initial value for years of experience
  });
  const handleChange = (e,index) => {
    const { name, value } = e.target;
    const updatedRows = [...newSkill];
    updatedRows[index][name] = value;
    setNewSkill(updatedRows);
  };

  const handleAddSkill = async (row) => {
    const data = {
      rows: [
        {
          jd_id: rowValue.jd_id,
          skill_type: row.type,
          keyword: row.keyword,
          number_of_year: row.years,
        },
      ],
    };
    const response = await insertIntoTable("jd_skills", data);
    setNewSkill([...newSkill,{...newRow}])
  };

  const handleSkillTypeChange = (event, newValue, index) => {
    const updatedRows = [...newSkill];
    updatedRows[index].type = newValue;
    setNewSkill(updatedRows);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <table
          style={{
            width: "100%",
            margin: "20px 0",
          }}
        >
          <thead>
            <tr style={{ height: "30px" }}>
              <th style={{ border: "1px solid #ddd" }}>Keyword</th>
              <th style={{ border: "1px solid #ddd" }}>Type</th>
              <th style={{ border: "1px solid #ddd" }}>Experience</th>
              <th style={{ border: "1px solid #ddd" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {newSkill.map((row, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid #ddd" }}>
                  <input
                    type="text"
                    name="keyword"
                    value={row.keyword}
                    onChange={(e)=>handleChange(e,index)}
                  />
                </td>

                <td style={{ border: "1px solid #ddd" }}>
                  <select name="type" value={row.type}  onChange={(e) =>
                    handleSkillTypeChange(e, e.target.value, index)
                  }>
                    <option value="">Select Type</option>
                    <option value="must_have">Must Have</option>
                    <option value="good_to_have">Good To Have</option>
                  </select>
                </td>
                <td style={{ border: "1px solid #ddd" }}>
                  {" "}
                  <input
                    type="text"
                    name="years"
                    value={row.years}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd" }}>
                  {index === newSkill.length - 1 && (
                    <button
                      variant="outlined"
                      onClick={() => handleAddSkill(row)}
                    >
                      Add
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
