import React from "react";

export function Type({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Type</label>
      </div>
      <div className="col-8">
        <select
          name="company_type"
          value={profDetail.company_type ===null?"notselected":(profDetail.company_type?.toLowerCase() || "")}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        >
          <option disabled={true} value="">
            Select
          </option>
          <option value="product">Product</option>
          <option value="service">Service</option>
          <option value="notselected">Not Selected</option>
          <option value="others">Other</option>
        </select>
      </div>
    </>
  );
}
