import React from "react";
import Modal from "../components/modal/Modal";
import BulkSendModal from "../modals/bulkSendJdModal";

export const SendMailTemplate = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
}) => {
  const rowData = data[index];
  const [value, setValue] = React.useState(initialValue);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div style={{ margin: "3px", color: "green" }}>
      {value ? (
        <div>Sent</div>
      ) : (
        <button onClick={() => setIsOpen(true)}>Send</button>
      )}
      <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
        <BulkSendModal
          selectedRows={[rowData]}
          setIsBulkSendModalOpen={setIsOpen}
        />
      </Modal>
    </div>
  );
};
