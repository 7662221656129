import React from "react";
import { DateRangePicker } from "rsuite";

export function DateRangeFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  const { afterToday } = DateRangePicker;
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id]).getTime()
      : 0;
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id]).getTime()
      : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(new Date(row.values[id]).getTime(), min);
      max = Math.max(new Date(row.values[id]).getTime(), max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  const onSubmit = (date) => {
    if (date.length > 0) {
      date[0].setHours(0, 0, 0, 1);
      date[1].setHours(23, 59, 59, 999);
      setFilter([date[0].getTime(), date[1].getTime()]);
    } else {
      setFilter([]);
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DateRangePicker
        // value = {filterValue?filterValue: undefined}
        placeholder="Select Date Range"
        disabledDate={afterToday()}
        onOk={onSubmit}
        onClean={onSubmit}
      />
    </div>
  );
}
