import React from "react";

export const EditableCellDropExternalSubStatus = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  data,
  changeLog,
}) => {
  const option1 = [
    "Will Join Surely",
    "Still Exploring - May Join",
    "Received another Offer. Wants to Negotiate",
    "Received anotther Offer. Will Not Join",
  ];
  const option2 = [
    "Asked to send documents",
    "Documents Submitted",
    "Negotiation Ongoing",
    "Offer Made",
    "Offer Accepted",
    "Offer Rejected",
  ];
  const option3 = [
    "Interview to be Scheduled",
    "Interview Rejected",
    "Inteview Feedback Pending",
    "Interview Scheduled",
    "Interview Cleared",
  ];
  const option4 = [
    "Need to Send Assignment",
    "Assignment Sent",
    "Submitted And Feedback Pending",
    "Assignment Not Submitted",
    "Assigment Rejected",
  ];
  const option5 = [
    "To be Screened",
    "Screen Reject",
    "Duplicate Candidate",
    "CV Approved",
  ];
  const option6 = [
    "Screen Reject",
    "Duplicate Candidate",
    "R1 reject",
    "R2 reject",
    "R3 reject",
    "Candidate backed out",
    "Rejected Internally",
  ];

  const [value, setValue] = React.useState(initialValue);
  //const [preStatus, setPreStatus] = React.useState(value);

  const externalStatus = data[index].external_status;
  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(e.target.value);
    updateData(index, id, e.target.value);
    changeLog(data[index].selected_candidate_id, "", "", value, newValue);
    //setPreStatus(e.target.value);
  };
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  var finalOptions = "";
  console.log("externalStatus", externalStatus);
  // ...

  if (externalStatus === "awaiting screening from client") {
    finalOptions = option5.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (externalStatus === "assignment round") {
    finalOptions = option4.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (["first round"].includes(externalStatus)) {
    finalOptions = option3.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (["middle stage round 1"].includes(externalStatus)) {
    finalOptions = option3.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (["middle stage round 2"].includes(externalStatus)) {
    finalOptions = option3.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (["final stage round"].includes(externalStatus)) {
    finalOptions = option3.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (externalStatus === "documentation/negotiation") {
    finalOptions = option2.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (externalStatus === "post offer followup") {
    finalOptions = option1.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (externalStatus === "rejected") {
    finalOptions = option6.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  }

  return (
    <select
      style={{ maxWidth: "80px" }}
      value={value || ""}
      onChange={onChange}
    >
      <option value="" disabled>
        Select
      </option>
      {finalOptions}
    </select>
  );
};
