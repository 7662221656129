import React from "react";

export const EditableCellDropExternalStatus = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
    changeLog,
    data,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    //const [preStatus, setPreStatus] = React.useState(value);
  
    const onChange = (e) => {
      const newValue = e.target.value;
      setValue(e.target.value);
      updateData(index, id, newValue);
      console.log("Trying");
      changeLog(data[index].selected_candidate_id, value, newValue, "", "");
      //setPreStatus(e.target.value);
    };
    // const onBlur = (e) =>{
    //   const newValue = e.target.value;
    //   setValue(newValue);
  
    // }
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <select value={value || ""} onChange={onChange} /*onBlur={onBlur}*/>
        {/* <option value="new">New</option> */}
        <option disabled={true} value="">
          Select
        </option>
        <option value="awaiting screening from client">
          Awaiting Screening from client{" "}
        </option>
        <option value="assignment round">Assignment Round </option>
        <option value="first round">First Round </option>
        <option value="middle stage round 1">Middle Stage Round 1 </option>
        <option value="middle stage round 2">Middle Stage Round 2 </option>
        <option value="final stage round">Final Stage Round </option>
        <option value="documentation/negotiation">
          Documentation/Negotiation{" "}
        </option>
        <option value="post offer followup">Post Offer Followup </option>
        <option value="rejected">Rejected</option>
      </select>
    );
  };