import React from "react";
import { DashAutoComplete } from "../components/autocomplete";

export function Company({profDetail,updateCompnay,currentIndex}) {
  return (
    <>
      <div className="col-4">
        <label>Company</label>
      </div>
      <div className="col-8">
        {/* <select type="text" name="current_company" value={profDetail.company.name} className="form-control" onChange={(e) => updateProfileDetail(e)}>
                  <option value="">-</option>
                </select> */}
        <DashAutoComplete
          tableName="company"
          index={currentIndex}
          id={"current_company"}
          updateData={updateCompnay}
          selectedValue={profDetail.company}
          intialValue={profDetail.company_name}
        />
      </div>
    </>
  );
}
