import React from "react";

export function CurrentCTC({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Current CTC</label>
      </div>
      <div className="col-8">
        <input
          type="text"
          name="current_ctc_float"
          value={profDetail.current_ctc_float}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        />
      </div>
    </>
  );
}
