import React from "react";

export const EditableCellDropNotice = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
      updateData(index, id, e.target.value);
    };
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <select value={value || "No"} onChange={onChange}>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
        <option value="NotEmployed">Not Employed</option>
        <option value="Others">Others</option>
      </select>
    );
  };
  