import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { DashAutoComplete } from "./components/autocomplete";
import { makeApiCall } from "./api";
import { EditableCellName } from "./columns/EditableCell";
import Table from "./tableView/Table.js";
import Header from "./header";
import './compensation.css';  



const BASEURL = process.env.REACT_APP_BASE_URL;

const useColumns =
      [{
        Header: "Experience",
        accessor: "exp",
        //Cell: EditableCellName,
        sticky: "left"
      },
      {
        Header: "Low",
        accessor: "min",
        maxWidth: 5

      },

      {
        Header: "Median",
        accessor: "median"

      },

      {
        Header: "High",
        accessor: "max",
        maxWidth: 5

      }
    ];

    const overallColumns =
    [
    {
      Header: "Low",
      accessor: "min",
      // maxWidth: "10"
    },

    {
      Header: "Median",
      accessor: "median"
    },

    {
      Header: "High",
      accessor: "max"
      // maxWidth: "10"
    }
  ];



const CompensationMeter = () => {
  const [overall, setOverall] = useState([]);
  const [experience, setExperience] = useState([]);
  const [designation, setDesignation] = useState("Backend");
  const [location, setLocation] = useState("Bengaluru");


function updateSelection(index,id,result){
    console.log(index,id,result);
}

async function onFilterSubmit(){
  const googleCred = Cookies.get("gSign");
  const baseurl = `${BASEURL}compensation`;
  const url = baseurl;
  const data = {"location":location,"designation":designation}
  //console.log(googleCred)
  const config = 
  {
    method: "POST",
    url: url,
    headers: {
      Authorization: googleCred
    },
    data: data,
    mode: "no-cors"
  }
  const result = await makeApiCall(config);
  console.log(result);
  setOverall([result["overall"]["current_ctc_float"]])
  setExperience(result["designation"])
}

const designations = ["Backend","Frontend","FullStack","Devops","Data Scientist","Data Analyst"]
const optionsDesignation = designations.map((framework, index) => (
  <option key={index}>{framework}</option>
));

const locations = ["Bengaluru","Delhi","Mumbai","Gurugram","Noida","Hyderabad","Chennai","Pune"]
const optionsLocation = locations.map((framework, index) => (
  <option key={index}>{framework}</option>
));

const onDesignationChange=(e)=>{
  setDesignation(e.target.value)
}

const onLocationChange=(e)=>{
  setLocation(e.target.value)
}

  return (
    <div>
      <Header link={""} linktype={"PDF"} data={""} CompanyName={""} />
     <div className="compensation-container">
      <div>
      <h1>{"Compensation Meter"}</h1>
      <select value={designation || ""} onChange={onDesignationChange}>
      {optionsDesignation}
      </select>
      <select value={location || ""} onChange={onLocationChange}>
      {optionsLocation}
      </select>
      <button onClick={onFilterSubmit}>Submit</button>
      </div>
    <div>
    <h2>Overall</h2>
    <Table
    columns={overallColumns}
    data={overall}
    />
    <h2>Experience Breakdown</h2>  
    <Table
    columns={useColumns}
    data={experience}
    />

    </div>
    </div>
    </div>
  );
};

export default CompensationMeter;
