import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
const BASEURL = process.env.REACT_APP_BASE_URL;

const notify = (notification) =>
  toast.success(notification, { position: toast.POSITION.TOP_CENTER });

export const sendMail = async (row) => {
  const googleCred = Cookies.get("gSign");

  const url = `${BASEURL}email/jd/${row.jd_id}`;

  const config = {
    method: "POST",
    url: url,
    headers: {
      "Authorization": googleCred,
    },
    data: { candidates: [row.candidate_id] },
    mode: "no-cors",
  };
  try {
    const response = await axios(config);
    notify("Email Sent !!");
    return response?.status;
  } catch (err) {
    console.log(err.message);
  }
};

export const sendWhatsAppMsg = (row) => {
  const mobile = row.mobile;
  const name = row.name;

  const msg = `I was trying to call you but couldn't connect over the call. Let me know " +
    "when can we connect and discuss about a job opportunity.%0a%0aThanks`;

  var links = `https://api.whatsapp.com/send?phone=+91${mobile}&text=Hi ${name},%0a%0a${msg}`;

  window.open(links, "_blank");
};




export const locationCities = () => {
  var cities = [
   {cat: "Tier 1", key: "Ahmedabad"},
   {cat: "Tier 1", key: "Bengaluru"},
   {cat: "Tier 1", key: "Chennai"},
   {cat: "Tier 1", key: "Delhi"},
   {cat: "Tier 1", key: "Hyderabad"},
   {cat: "Tier 1", key: "Kolkata"},
   {cat: "Tier 1", key: "Mumbai"},
   {cat: "Tier 1", key: "Pune"},
    {cat: "Tier 2", key: "Agra"},
    {cat: "Tier 2", key: "Ajmer"},
    {cat: "Tier 2", key: "Aligarh"},
    {cat: "Tier 2", key: "Amravati"},
    {cat: "Tier 2", key: "Amritsar"},
    {cat: "Tier 2", key: "Anand"},
    {cat: "Tier 2", key: "Asansol"},
    {cat: "Tier 2", key: "Aurangabad"},
    {cat: "Tier 2", key: "Bareilly"},
    {cat: "Tier 2", key: "Belagavi"},
    {cat: "Tier 2", key: "Bhavnagar"},
    {cat: "Tier 2", key: "Bhilai"},
    {cat: "Tier 2", key: "Bhiwandi"},
    {cat: "Tier 2", key: "Bhopal"},
    {cat: "Tier 2", key: "Bhubaneswar"},
    {cat: "Tier 2", key: "Bikaner"},
    {cat: "Tier 2", key: "Bilaspur"},
    {cat: "Tier 2", key: "Bokaro Steel City"},
    {cat: "Tier 2", key: "Chandigarh"},
    {cat: "Tier 2", key: "Coimbatore"},
    {cat: "Tier 2", key: "Cuttack"},
    {cat: "Tier 2", key: "Dehradun"},
    {cat: "Tier 2", key: "Dhanbad"},
    {cat: "Tier 2", key: "Durgapur"},
    {cat: "Tier 2", key: "Erode"},
    {cat: "Tier 2", key: "Faridabad"},
    {cat: "Tier 2", key: "Firozabad"},
    {cat: "Tier 2", key: "Ghaziabad"},
    {cat: "Tier 2", key: "Gorakhpur"},
    {cat: "Tier 2", key: "Guntur"},
    {cat: "Tier 2", key: "Gurugram"},
    {cat: "Tier 2", key: "Guwahati"},
    {cat: "Tier 2", key: "Gwalior"},
    {cat: "Tier 2", key: "Hamirpur"},
    {cat: "Tier 2", key: "Hubballi–Dharwad"},
    {cat: "Tier 2", key: "Indore"},
    {cat: "Tier 2", key: "Jabalpur"},
    {cat: "Tier 2", key: "Jaipur"},
    {cat: "Tier 2", key: "Jalandhar"},
    {cat: "Tier 2", key: "Jalgaon"},
    {cat: "Tier 2", key: "Jammu"},
    {cat: "Tier 2", key: "Jamnagar"},
    {cat: "Tier 2", key: "Jamshedpur"},
    {cat: "Tier 2", key: "Jhansi"},
    {cat: "Tier 2", key: "Jodhpur"},
    {cat: "Tier 2", key: "Kakinada"},
    {cat: "Tier 2", key: "Kalaburagi"},
    {cat: "Tier 2", key: "Kannur"},
    {cat: "Tier 2", key: "Kanpur"},
    {cat: "Tier 2", key: "Karnal"},
    {cat: "Tier 2", key: "Kochi"},
    {cat: "Tier 2", key: "Kolhapur"},
    {cat: "Tier 2", key: "Kollam"},
    {cat: "Tier 2", key: "Kozhikode"},
    {cat: "Tier 2", key: "Kurnool"},
    {cat: "Tier 2", key: "Lucknow"},
    {cat: "Tier 2", key: "Ludhiana"},
    {cat: "Tier 2", key: "Madurai"},
    {cat: "Tier 2", key: "Malappuram"},
    {cat: "Tier 2", key: "Mangaluru"},
    {cat: "Tier 2", key: "Mathura"},
    {cat: "Tier 2", key: "Meerut"},
    {cat: "Tier 2", key: "Moradabad"},
    {cat: "Tier 2", key: "Mysuru"},
    {cat: "Tier 2", key: "Nagpur"},
    {cat: "Tier 2", key: "Nanded"},
    {cat: "Tier 2", key: "Nashik"},
    {cat: "Tier 2", key: "Nellore"},
    {cat: "Tier 2", key: "Noida"},
    {cat: "Tier 2", key: "Patna"},
    {cat: "Tier 2", key: "Prayagraj"},
    {cat: "Tier 2", key: "Puducherry"},
    {cat: "Tier 2", key: "Purulia"},
    {cat: "Tier 2", key: "Raipur"},
    {cat: "Tier 2", key: "Rajamahendravaram"},
    {cat: "Tier 2", key: "Rajkot"},
    {cat: "Tier 2", key: "Ranchi"},
    {cat: "Tier 2", key: "Ratlam"},
    {cat: "Tier 2", key: "Rourkela"},
    {cat: "Tier 2", key: "Salem"},
    {cat: "Tier 2", key: "Sangli"},
    {cat: "Tier 2", key: "Shimla"},
    {cat: "Tier 2", key: "Siliguri"},
    {cat: "Tier 2", key: "Solapur"},
    {cat: "Tier 2", key: "Srinagar"},
    {cat: "Tier 2", key: "Surat"},
    {cat: "Tier 2", key: "Thanjavur"},
    {cat: "Tier 2", key: "Thiruvananthapuram"},
    {cat: "Tier 2", key: "Thrissur"},
    {cat: "Tier 2", key: "Tiruchirappalli"},
    {cat: "Tier 2", key: "Tirunelveli"},
    {cat: "Tier 2", key: "Tiruvannamalai"},
    {cat: "Tier 2", key: "Ujjain"},
    {cat: "Tier 2", key: "Vadodara"},
    {cat: "Tier 2", key: "Varanasi"},
    {cat: "Tier 2", key: "Vasai-Virar"},
    {cat: "Tier 2", key: "Vellore"},
    {cat: "Tier 2", key: "Vijayapura"},
    {cat: "Tier 2", key: "Vijayawada"},
    {cat: "Tier 2", key: "Visakhapatnam"},
    {cat: "Tier 2", key: "Warangal"},
    {cat: "Tier 3", key: "Others"},
  ];
  const options = cities.map((city) => ({ value: city.key, label: city.key, cat: city.cat }));
  return options;
};
