import React from "react";
import { useTable } from 'react-table'
import {useState, useEffect} from 'react'
import "./new-jd.css";
import styled from 'styled-components'
import { CustomeDateformat } from "../columns/customDateFormat";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { insertIntoTable, loadTableData } from "../api";




const notify = (notification) =>
  toast.success(notification, { position: toast.POSITION.TOP_CENTER });


const Styles = styled.div`
  table {
    margin-top: 5px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
        text-align:cetner;
        padding: 1px;
      margin: 0;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


function TableRequestMaster({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }


function NewCandidatesRerquest({setToogleCandidates,}) {

  const [quantity, setQuantity] = React.useState('')
  const [data, setData] = useState([]);


  const onSave = ()=>{
    InsertCandidatesRequest(quantity)
    setToogleCandidates(false)
    notify(quantity + " candidates requested succesfully !")
  }

    // Fetch Data from DB
    async function FetchMasterRequest()
    {
      const {result} = await await loadTableData("request_master", {
        order: {
          column: "created_at",
          ascending: false
        },
        limit: 5
      });
    }

    async function InsertCandidatesRequest(quantity){
      const data = {rows:[{ type: 'candidates', status: 'requested', quantity:quantity}]};
        await insertIntoTable("request_master",data);
       }


  const ColumnsForHistory = React.useMemo(()=>[
    {
        Header: 'Created',
        accessor: 'created_at',
        Cell: CustomeDateformat
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Delivery Status',
        accessor: 'delivered',
      },
  ], []);
  useEffect( ()=> { FetchMasterRequest()}, []);

  return (
    <div  className="background" >
      <div className="container" style={{ height:"auto"}}>
        <div className="titleCloseBtn">
            <h4>New Candidates Sourcing</h4>
          <button onClick={()=>{setToogleCandidates(false)}}>X</button>
        </div>
        <div>Request New Candidates</div>
        <div>No Of Candidates:</div>
        <input type ="text" style={{margin:"2px",border:"1px solid grey", borderRadius:"5px", height:"40px"}} onChange = {(e)=>{setQuantity(e.target.value)}}></input>
        <button onClick = {onSave}>Save</button>
        <div>
        <Styles>
            <TableRequestMaster columns={ColumnsForHistory} data = {data}/>
        </Styles>
        </div>
      </div>
    </div>
  );
}

export default NewCandidatesRerquest;