import React from "react";
import { CustomeDateformat } from "../columns/customDateFormat";
import { DateRangeFilter } from "../filters/dateRangeFilter";
import { dateBetweenFilterFn, statusFilterFn } from "./Table";
import { EditableCellName } from "../columns/editableCellName";
import { EditableCellEmail } from "../columns/editableCellEmail";
import { EditableCellExp } from "../columns/editableCellExp";
import { EditableCellDropCompany } from "../columns/editableCellDropCompany";
import { SelectColumnFilter } from "../filters/selectColumnFilter"; 
import { EditableCellDropCompanytype } from "../columns/EditableCellDropCompanytype"; 
import { NumberRangeColumnFilter } from "../filters/numberRangeColumnFilter";
import { EditableCellRange } from "../columns/editableCellRange";
import { EditableCell } from "../columns/EditableCell";
import { EditableCellSkill } from "../columns/editableCellSkill";
import { EditableCellJobType } from "../columns/editableCellJobType";
import { EditableCellDropLocation } from "../columns/editableCellDropLocation";
import { ColumnFilterForInternal } from "../filters/columnFilterForInternal";
import { EditableCellDropNotice } from "../columns/editableCellDropNotice";
import { EditableCellRangeNotice } from "../columns/editableCellRangeNotice";
import { EditableCellDropCollege } from "../columns/editableCellDropCollege";
import { WhatsApp } from "../columns/whatsapp";
import { EditableCellSwitch } from "../columns/editableCellSwitch";
import { EditableCellSource } from "../columns/editableCellSource";
export const useAppColumns = () => {
  return React.useMemo(
    () => [
      {
        Header: "Verified",
        accessor: "verified",
        Filter: SelectColumnFilter,
        Cell: EditableCellSwitch,
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: "10",
        // sticky: 'left'
        Cell: EditableCellName,
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        // sticky: 'left'
      },
      {
        Header: "Email Id",
        accessor: "email",
        Cell: EditableCellEmail,
      },
      {
        Header: "Total Work Experience",
        accessor: "total_exp",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellExp,
      },
      {
        Header: "Current Company",
        accessor: "company.name",
        Cell: EditableCellDropCompany,
      },
      {
        Header: "Product/Non-product",
        accessor: "company.type",
        Filter: SelectColumnFilter,
        Cell: EditableCellDropCompanytype,
      },
      {
        Header: "Current CTC",
        accessor: "current_ctc_float",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellRange,
      },
      {
        Header: "Expected CTC",
        accessor: "min_expected_ctc_float",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellRange,
      },
      {
        Header: "Preferred job designation",
        accessor: "designation",
        Cell: EditableCell,
      },
      {
        Header: "Skill * Experience",
        accessor: "skill",
        Cell: EditableCellSkill,
      },
      {
        Header: "Job Preference Type",
        accessor: "job_prefer_type",
        Filter: SelectColumnFilter,
        Cell: EditableCellJobType,
      },
      {
        Header: "Job Preference Location",
        accessor: "job_prefer_location",
        Cell: EditableCellDropLocation,
        maxWidth: 10,
      },
      {
        Header: "Serving Notice Status",
        accessor: "notice_period_status",
        Filter: SelectColumnFilter,
        Cell: EditableCellDropNotice,
      },
      {
        Header: "Serving Notice Days",
        accessor: "notice_period_days",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellRangeNotice,
      },
      {
        Header: "Last Working Day",
        accessor: "last_working_day",
        Cell: CustomeDateformat,
        Filter: DateRangeFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Holding offer",
        accessor: "holding_offer",
        Filter: SelectColumnFilter,
        Cell: EditableCellDropNotice,
      },
      {
        Header: "Holding CTC ",
        accessor: "holding_ctc",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        Cell: EditableCellRange,
      },
      {
        Header: "College Name - Highest Degree",
        accessor: "college.name",
        Cell: EditableCellDropCollege,
      },
      // {
      //   Header: "Candidate status",
      //   accessor: "candidate_status",
      //   Filter: SelectColumnFilter,
      //   Cell: EditableCellDropCandidateStatus,
      // },
      {
        Header: "Source",
        accessor: "source",
        Cell: EditableCellSource,
      },
      {
        Header: "Days since candidate was added to DB",
        accessor: "days_candidate_added_to_db",
        Filter: "",
      },
      {
        Header: "Remark",
        accessor: "remark",
        Cell: EditableCell,
      },
      {
        Header: "WhatsAppLInk",
        Cell: WhatsApp,
      },
    ],
    []
  );
};
