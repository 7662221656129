import React from 'react';

export function Email({profDetail,updateProfileDetail}){
    return(
        <>
         <div className="col-4">
                <input
                  type="text"
                  name="email"
                  value={profDetail.email}
                  className="form-control"
                  style={{ marginRight: "1rem" }}
                  placeholder={"Enter email"}
                  onChange={(e) => updateProfileDetail(e)}
                />
              </div>
        </>
    )
}