import React,{useState}from "react";
import { Status } from "./status";
import { sendMail, sendWhatsAppMsg } from "../utility";
import { SubStatus } from "./subStatus";
import { Email } from "./Email";
import { Notes } from "./Notes";
import { Resume } from "./Resume";
import { Experience } from "./Experience";
import { CurrentCTC } from "./Current_CTC";
import { Hike } from "./hike";
import { Skills } from "./Skills";
import { ExpectedCTC } from "./Expected_CTC";
import { JobPreference } from "./jobPreference";
import { College } from "./college";
import { CurrentLocation } from "./currentLocation";
import { PreferredLocation } from "./preferredLocation";
import { Designation } from "./designation";
import { HoldingCTC } from "./holdingCTC";
import { Type } from "./type";
import { HoldingOffer } from "./holdingOffer";
import { Company } from "./company";
import { ServiceNotice } from "./serviceNotice";
import { NoticePeriod } from "./noticePeriod";
import { LWD } from "./updateLWD";
import { combinedOptions } from "./statusSubstatusDictionary";
import './profileDetails.css';

export function ProfileView({
  profDetail,
  updateProfileDetail,
  currentIndex,
  updateCollege,
  locationUpdate,
  changeHandler,
  hiddenFileInput,
  openFileChooser,
  LWDDate,
  expMonth,
  expYear,
  renderSelectOption,
  updateLWD,
  updateExpDropdown,
  cityOptions,
  pageType,
  renderSelectedLocations,
  updateCompnay,
  updateSkill,
  renderExpValue,
}) {

  var keyValue;
  if(pageType == 'Internal'){
    keyValue = profDetail.internal_status;
    if(keyValue === null){
      keyValue = 'new'
    }
  }else{
    keyValue = profDetail.external_status;
    if(keyValue === null){
      keyValue = 'awaiting screening from client'
    }
  }
  const [selectedKey, setSelectedKey] = useState(keyValue);

  const handleKeyChange = (value) => {
    setSelectedKey(value);
  };

  return (
    <>
      <div
        className="profile-detail-section"
        style={{ backgroundColor: "#ecebeb", padding: "10px" }}
      >
        {/* row 1 */}
        <div className="row mb-1">
          <div className="col-3">
            <label>{profDetail.name}</label>
          </div>
          <Status
            pageType={pageType}
            profDetail={profDetail}
            updateProfileDetail={updateProfileDetail}
            selectedKey={selectedKey}
            handleKeyChange={handleKeyChange}
          />
          <div className="col-3">
            <button
              className="btn btn-primary"
              onClick={() => sendMail(profDetail)}
            >
              Send Instant JD
            </button>
          </div>
        </div>
        {/* row 2 */}
        <div className="row mb-1">
          <div className="col-3">
            <label>{profDetail.mobile}</label>
          </div>
          <SubStatus
            profDetail={profDetail}
            updateProfileDetail={updateProfileDetail}
            pageType={pageType}
            selectedKey={selectedKey}
            combinedOptions={combinedOptions}
          />
          <div className="col-3">
            <button
              className="btn btn-primary"
              onClick={() => sendWhatsAppMsg(profDetail)}
            >
              Send Whatsapp
            </button>
          </div>
        </div>
        {/* row 3 */}
        <div className="row mb-1">
          <Email
            profDetail={profDetail}
            updateProfileDetail={updateProfileDetail}
          />
          <Notes
            profDetail={profDetail}
            updateProfileDetail={updateProfileDetail}
          />
          <div className="col-3">
            <Resume
              profDetail={profDetail}
              hiddenFileInput={hiddenFileInput}
              changeHandler={changeHandler}
              openFileChooser={openFileChooser}
            />
          </div>
        </div>
        <div
          className="profile-detail-section"
          style={{ backgroundColor: "#ffffff", padding: "10px" }}
        >
          {/* row 1 */}
          <div className="row mb-1">
            <div className="col-6">
              <div className="row">
                <Experience
                  expYear={expYear}
                  updateExpDropdown={updateExpDropdown}
                  renderSelectOption={renderSelectOption}
                  expMonth={expMonth}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <CurrentCTC
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
                <Hike
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
            </div>
          </div>
          {/* row 2 */}
          <div className="row mb-1">
            <div className="col-6">
              <Skills
                profDetail={profDetail}
                updateSkill={updateSkill}
                renderExpValue={renderExpValue}
              />
            </div>
            <div className="col-6">
              <div className="row mb-2">
                <ExpectedCTC
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
              <div className="row mb-2">
                <HoldingOffer
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
              <div className="row mb-2">
                <HoldingCTC
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
              <div className="row mb-2">
                <JobPreference
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
            </div>
          </div>
          {/* row 3 */}
          {/* row 6 */}
          <div className="holding-candidate-container">
          <div className="col-6">
          <div className="row mb-1">
            <div className="col-12">
              <div className="row">
                <Company
                  currentIndex={currentIndex}
                  updateCompnay={updateCompnay}
                  profDetail={profDetail}
                />
              </div>
            </div>
          </div>
          {/* row 7 */}
          <div className="row mb-1">
            <div className="col-12">
              <div className="row">
                <Type
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
            </div>
          </div>
          {/* row 8 */}
          <div className="row mb-1">
            <div className="col-12">
              <div className="row">
                <Designation
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
            </div>
          </div>
          {/* row 9 */}
          <div className="row mb-1">
            <div className="col-12">
              <div className="row mb-1">
                <CurrentLocation
                  profDetail={profDetail}
                  cityOptions={cityOptions}
                  locationUpdate={locationUpdate}
                  renderSelectedLocations={renderSelectedLocations}
                />
              </div>
            </div>
          </div>
          </div>
          <div>
          <div className="row mb-1">
            <div className="col-12">
              <div className="row">
                <NoticePeriod
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
            </div>
          </div>
          {/* row 4 */}
          <div className="row mb-1">
            <div className="col-12">
              <div className="row">
                <LWD LWDDate={LWDDate} updateLWD={updateLWD} />
              </div>
            </div>
          </div>
          {/* row 5 */}
          <div className="row mb-1">
            <div className="col-12">
              <div className="row">
                <ServiceNotice
                  profDetail={profDetail}
                  updateProfileDetail={updateProfileDetail}
                />
              </div>
            </div>
          </div>
          <div className="row">
                <College
                  currentIndex={currentIndex}
                  updateCollege={updateCollege}
                  profDetail={profDetail}
                />
              </div>
          <div className="col-12">
              <div className="row">
                <PreferredLocation
                  renderSelectedLocations={renderSelectedLocations}
                  profDetail={profDetail}
                  cityOptions={cityOptions}
                  locationUpdate={locationUpdate}
                />
              </div>
            </div>

          </div>

        </div>
        </div>
      </div>
    </>
  );
}
