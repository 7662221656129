import React from "react";

const style = {
  "input" : {
        width : "50px"
  }
}

export const EditableCellNumber = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateData(index, id, value);
    };
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return <input className="input-number" style={style.input} value={value || ""} onChange={onChange} onBlur={onBlur} />;
  };