import React from "react";

export function ServiceNotice({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Service Notice</label>
      </div>
      <div className="col-8">
        <select
          name="notice_period_status"
          value={profDetail.notice_period_status || ""}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        >
          <option disabled={true} value="">
            Select
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
          <option value="NotEmployed">Not Employed</option>
          <option value="Others">Others</option>
        </select>
      </div>
    </>
  );
}
