import React, { useState } from "react";
import Modal from "../components/modal/Modal";
import Table from "../tableView/Table";
import { SkillModal } from "../modals/skillModal";

const EditableSkill = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState("");
  const [newSkill, setNewSkill] = useState([
    {
      type: "", // Initial value for skill type
      keyword: "", // Initial value for keyword
      years: "", // Initial value for years of experience
    },
  ]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button onClick={openModal}>Add Skill</button>
      <Modal handleClose={closeModal} isOpen={isModalOpen}>
        <SkillModal
          onClose={closeModal}
          newSkill={newSkill}
          setNewSkill={setNewSkill}
          rowValue={data[index]}
          action={action}
          setAction={setAction}
        />
      </Modal>
    </div>
  );
};

export default EditableSkill;
