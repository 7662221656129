import { SelectColumnFilter } from "../filters/selectColumnFilter";
import { EditableCellDropCompanytype } from "../columns/EditableCellDropCompanytype";
import { EditableCellToggle } from "../columns/EditableCellToggle"; 
import { EditableCell } from "../columns/EditableCell";
import { EditableCellNumber } from "../columns/editableCellNumber"; 
import { EditableCellJobType } from "../columns/editableCellJobType";
import { EditableCellDropLocation } from "../columns/editableCellDropLocation";
import { EditableCellDropCollegePreference } from "../columns/editableCellDropCollegePreference"; 
import { EditableCellDropGoodToHave } from "../columns/editableCellDropGoodToHave"; 
import { ResumeLink } from "../columns/resumeLink"; 
import { LinksInternal,LinksExternal } from "./Table";
import EditableSkill from "../columns/editableSkills";
import EditableInterview from "../columns/editableInterview";
import { JdCompany } from "../columns/JdCompany";
import EditablePOC from "../columns/editablePOC";
import { EditableCellRemarks } from "../columns/editableCellRemarks";

export const useJdColumns = () => {
  return [
    {
      Header: "Internal Detail",
      Cell: LinksInternal,
    },
    {
      Header: "External Detail",
      Cell: LinksExternal,
    },
    {
      Header: "Company Name",
      accessor: "company_name",
      maxWidth: "10",
      Cell: JdCompany,
    },
    {
      Header: "Job Id",
      accessor: "jd_id",
      Filter: "",
    },
    {
      Header: "Designation",
      accessor: "designation",
      Cell: EditableCell,
    },
    {
      Header: "Active",
      accessor: "active",
      Cell: EditableCellToggle,
    },
    {
      Header: "Min Exp",
      accessor: "min_exp",
      maxWidth: 5,
      width: 5,
      Cell: EditableCellNumber,
    },
    {
      Header: "Max Exp",
      accessor: "max_exp",
      maxWidth: 5,
      width: 5,
      Cell: EditableCellNumber,
    },
    {
      Header: "Min CTC",
      accessor: "min_ctc",
      Cell: EditableCellNumber,
    },
    {
      Header: "Max CTC",
      accessor: "max_ctc",
      Cell: EditableCellNumber,
    },
    {
      Header: "Job Site",
      accessor: "job_site",
      Cell: EditableCellJobType,
    },
    {
      Header: "Job Location",
      accessor: "job_location",
      Cell: EditableCellDropLocation,
    },
    {
      Header: "College Preference",
      accessor: "college_preference",
      Cell: EditableCellDropCollegePreference,
    },
    {
      Header: "Max Notice Period",
      accessor: "max_notice_period",
      Cell: EditableCellNumber,
    },
    {
      Header: "SKills",
      accessor: "skills",
      Cell: EditableSkill,
    },
    {
      Header: "Interview",
      accessor: "interview",
      Cell: EditableInterview,
    },
    {
      Header: "POC",
      accessor: "poc",
      Cell: EditablePOC,
    },
    {
      Header: "Company Type",
      accessor: "company_type",
      Filter: SelectColumnFilter,
      Cell: EditableCellDropCompanytype,
    },
    {
      Header: "JD Link",
      accessor: "jd_link",
      Filter: "",
      Cell: ResumeLink,
    },
    {
      Header: "Users",
      accessor: "assigned_kam",
      Cell: EditableCellJobType,
    },
    
    {
      Header: "Remark",
      accessor: "remark",
      Cell: EditableCellRemarks,
      Filter: "",
    }
  ];
};
