import React from "react";

export function Experience({expYear,expMonth,updateExpDropdown,renderSelectOption}){
    return(
        <>
         <div className="col-4">
                      <label>Experience</label>
                    </div>
                    <div className="col-8">
                      <div style={{ display: "flex" }}>
                        <select
                          name="year"
                          value={expYear}
                          onChange={updateExpDropdown}
                        >
                          <option value="0" disabled>
                            0 Year
                          </option>
                          {renderSelectOption("Year")}
                        </select>
                        <select
                          name="month"
                          value={expMonth}
                          onChange={updateExpDropdown}
                        >
                          <option value="0" disabled>
                            0 Month
                          </option>
                          {renderSelectOption("Month")}
                        </select>
                      </div>
                    </div>
                    </>
    )
}