import React from "react";

export const EditableCellDropCompanytype = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
      updateData(index, id, e.target.value);
    };
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <select value={value || ""} onChange={onChange}>
        <option disabled value="">
          select
        </option>
        <option value="product">Product</option>
        <option value="service">Service</option>
        <option value="others">Other</option>
      </select>
    );
  };