import React from "react";

export function DefaultColumnFilterStatus({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
      onClick={(e) => e.stopPropagation()}
    />
  ); //End -Fix to handle the filter ID-355
}
