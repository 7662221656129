export const internalSubStatusOption1 = ["Interested"];
export const internalSubStatusOption2 = [
  "High CTC expected",
  "6 days work week",
  "Location not suitable",
  "Not looking for job change",
  "Already in progress with this company",
  "Not fit for the role due to designation/ seniority / skill",
  "Company brand / Company culture/ Company size / Company a startup",
];
export const internalSubStatusOption3 = ["Sent JD"];
export const internalSubStatusOption4 = [
  "Asked to call later",
  "Called but did not pick",
  "Not Reachable",
  "Wrong Number",
  "Busy",
];

export const externalSubStatusOption1 = [
  "Will Join Surely",
  "Still Exploring - May Join",
  "Received another Offer. Wants to Negotiate",
  "Received anotther Offer. Will Not Join",
];
export const externalSubStatusOption2 = [
  "Asked to send documents",
  "Documents Submitted",
  "Negotiation Ongoing",
  "Offer Made",
  "Offer Accepted",
  "Offer Rejected",
];
export const externalSubStatusOption3 = [
  "Interview to be Scheduled",
  "Interview Rejected",
  "Inteview Feedback Pending",
  "Interview Scheduled",
  "Interview Cleared",
];
export const externalSubStatusOption4 = [
  "Need to Send Assignment",
  "Assignment Sent",
  "Submitted And Feedback Pending",
  "Assignment Not Submitted",
  "Assigment Rejected",
];
export const externalSubStatusOption5 = [
  "To be Screened",
  "Screen Reject",
  "Duplicate Candidate",
  "CV Approved",
];
export const externalSubStatusOption6 = ["Screen Reject", "Duplicate Candidate","R1 reject","R2 reject",
"R3 reject","Candidate backed out","Rejected Internally"];