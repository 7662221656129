import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Header from "../header";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";

const CustomPDFViewer = () => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { id } = useParams();
  const url = `https://app.pragti.in/internal-api/resume/${id}`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
      <Header link={""} linktype={"PDF"} data={""} CompanyName={""} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          marginTop: "5rem",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "800px",
            height: "100%",
            border: "1px solid #ddd",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
              pageNumber={pageNumber}
            />
          </Document>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "10px",
            }}
          >
            <button
              style={{
                backgroundColor: "#17E5C3",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "16px",
                fontStyle: "normal",
                marginLeft: "120px",
                width: "50%",
                height: "40px",
                marginRight:"2rem"
              }}
              onClick={async () => {
                const response = await fetch(
                  `https://app.pragti.in/internal-api//resume/` + id
                );
                const blob = await response.blob();
                const fileURL = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = `resume-file-${id}`; // Set a different file name without the .pdf extension
                link.click();
              }}
            >
              Download
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0",
          }}
        >
          <button onClick={previousPage} disabled={pageNumber <= 1}>
            Previous
          </button>
          <span>
            Page {pageNumber} of {numPages}
          </span>
          <button onClick={nextPage} disabled={pageNumber >= numPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomPDFViewer;
