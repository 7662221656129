import React from "react";
import CustomPDFViewer from "./CustomPDFViewer";
import ParserDetails from "./ParserDetail";
import Header from "../header";
import { useParams } from "react-router-dom";
import './resume.css';

const ResumeViewer = () => {

    const { id } = useParams();
    return (
        <div className="resume-overall-container">
            <Header link={""} linktype={"PDF"} data={""} CompanyName={""} />
            <div className="resume-container">
            <div className="left-resume-container">
                <CustomPDFViewer
                 id = {id}  
                />
           </div>
            <div className="right-resume-container">
                <ParserDetails
                id = {id}
                />
            </div>
        </div>
        </div>
        )
}

export default ResumeViewer;