import React from "react";
import Multiselect from "multiselect-react-dropdown";

export function CurrentLocation({profDetail,renderSelectedLocations,locationUpdate,cityOptions}) {
  return (
    <>
      <div className="col-4">
        <label>Current Location</label>
      </div>
      <div className="col-8">
        <Multiselect
          style={{ minHeight: 35, width: 300 }}
          name="current_location"
          selectedValues={renderSelectedLocations(profDetail.current_location)}
          options={cityOptions}
          displayValue="value"
          onSelect={(e) => locationUpdate(e, "current_location")}
          onRemove={(e) => locationUpdate(e, "current_location")}
        />
      </div>
    </>
  );
}
