import React from "react";
import Modal from "../components/modal/Modal";
import { Skill } from "../modals";

export const EditableCellSkill = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
  updateData,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(JSON.stringify(initialValue));
  const [isOpen, setIsOpen] = React.useState(false);

  // We'll only update the external data when the input is blurred
  const UpdateSkils = (skills) => {
    updateData(index, id, skills);
  };
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => setValue(JSON.stringify(initialValue)), [initialValue]);
  var toggle = false;
  if (value) {
    var indexSkill = JSON.parse(value);
    if (typeof indexSkill === "string") {
      toggle = true;
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <input readOnly value={value || ""} />
      <button onClick={() => setIsOpen(true)}>Edit</button>
      <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
        <Skill
          rowValue={data[index].skill}
          setOpenModal={setIsOpen}
          UpdateSkils={UpdateSkils}
        />
      </Modal>
    </div>
  );
};
