import React from "react";
import { DashAutoComplete } from "../components/autocomplete";

export function College({profDetail,currentIndex,updateCollege}) {
  return (
    <>
      <div className="col-4">
        <label>College Name</label>
      </div>
      <div className="col-8">
        {/* <select name="college_name_highest_degree" className="form-control" onChange={(e) => updateProfileDetail(e)}>
                  <option value="">-</option>
                </select> */}
        <DashAutoComplete
          tableName="college"
          index={currentIndex}
          id={"college_name_highest_degree"}
          updateData={updateCollege}
          selectedValue={profDetail.college}
          intialValue={profDetail.college_name}
        />
      </div>
    </>
  );
}
