import React from "react";
import Modal from "../components/modal/Modal";
import { sendMail } from "../utility";
import { Confirm } from "./confirmation";
import {insertIntoTable, loadTableData,updateTableData} from "../api";

function BulkSendModal({ selectedRows,setIsBulkSendModalOpen }) {
    
  async function insertMailRequest(candidate_id, jd_id, html) {
    const user_id = window.sessionStorage.getItem('UserID');
    const data = {rows:[{ candidate_id: candidate_id, 
                      jd_id: jd_id, html: JSON.stringify(html),
                      user_id: user_id
                    }]};
    await insertIntoTable("mail_tracking",data);
  }

  const handleBulkJDSend = async(html)=>{
        for(let i=0;i<selectedRows.length;i++){
          //sendMail(selectedRows[i],html);
          insertMailRequest(selectedRows[i].candidate_id, 
            selectedRows[i].jd_id,
            html
            )
        }
        setIsBulkSendModalOpen(false);
      }

  return (
    <div>
      <h6> * {selectedRows.length} candidates are selected.</h6>
      <p> Do you want to send JDS to candidates?</p>
      <Confirm setOpenModal={setIsBulkSendModalOpen} SendMailTrigger={handleBulkJDSend}/>
    </div>
  );
}

export default BulkSendModal;

