import React from "react";

export function ResumeLinkModal({ rowValue, setOpenModal, updateResumeLink,row,column }) {
  const [value, setValue] = React.useState(rowValue);
  console.log(rowValue)
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateResumeLink(row,column,value);
  };

  const updateData = () => {
    updateResumeLink(row,column,value);
    setOpenModal(false);
  };

  return (
    <div className="modall-content">
      <div className="modall-header">Resume Link</div>
      <div className="modall-body">
        <div className="modall-body-row">
          <label>Link</label>
          <input className="form-control" value={value || ""} onChange={onChange} onBlur={onBlur} />
        </div>
      </div>
      <div className="modall-footer">
        <button onClick={() => setOpenModal(false)}>Cancel</button>
        <button onClick={updateData}>Save</button>
      </div>
    </div>
  );
}
