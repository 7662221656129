import React from 'react';


export function UserStatus({userStatus,renderStatusDate}){

    return (
        <>
        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              border: "1px solid #ccc",
              fontWeight: "bold",
            }}
          >
            <span style={{ width: "33%", textAlign: "left" }}>
              Created Date
            </span>
            <span style={{ width: "33%", textAlign: "left" }}>
              From Status
            </span>
            <span style={{ width: "33%", textAlign: "left" }}>To Status</span>
          </div>
          {userStatus?.length > 0 ? (
            userStatus.map((status, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  border: "1px solid #ccc",
                }}
              >
                <span style={{ width: "33%", textAlign: "left" }}>
                  {renderStatusDate(status.created_at)}
                </span>
                <span style={{ width: "33%", textAlign: "left" }}>
                  {status.from_status}
                </span>
                <span style={{ width: "33%", textAlign: "left" }}>
                  {status.to_status}
                </span>
              </div>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                border: "1px solid #ccc",
              }}
            >
              <span>No records found</span>
            </div>
          )}
          </>
    )
}