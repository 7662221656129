import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import { insertIntoTable, loadTableData, updateTableData } from "../api";
import { ColorRing } from "react-loader-spinner";

import Table, {
  dateBetweenFilterFn,
} from "./Table";
import { SelectColumnFilterHoldingOffer } from "../filters/selectColumnFilterHoldingOffer";
import { NumberRangeColumnFilter } from "../filters/numberRangeColumnFilter";
import { DateRangeFilter } from "../filters/dateRangeFilter";
import { SelectColumnFilter } from "../filters/selectColumnFilter";
import { EditableCellDropExternalSubStatus } from "../columns/editableCellDropExternalSubStatus";
import { EditableCellDropExternalStatus } from "../columns/editableCellDropExternalStatus";
import { EditableCellEmail } from "../columns/editableCellEmail";
import { EditableCellName } from "../columns/editableCellName";
import { EditableCellExp } from "../columns/editableCellExp";
import { EditableCellSkill } from "../columns/editableCellSkill";
import { EditableCellDropNotice } from "../columns/editableCellDropNotice";
import { EditableCellRangeNotice } from "../columns/editableCellRangeNotice";
import { CustomeDateformat } from "../columns/customDateFormat";
import { EditableCellHoldingOffer } from "../columns/editableCellHoldingOffer";
import { EditableCellRange } from "../columns/editableCellRange";
import { EditableCellDropCollege } from "../columns/editableCellDropCollege";
import { EditableCellDropCandidateStatus } from "../columns/editableCellDropCandidateStatus";
import { ResumeDowmload } from "../columns/resumeDownload";
import { EditableCellDropLocation } from "../columns/editableCellDropLocation";
import { EditableCellJobType } from "../columns/editableCellJobType";
import { EditableCell } from "../columns/EditableCell";
import { EditableCellDropCompany } from "../columns/editableCellDropCompany";
import { EditableCellDropCompanytype } from "../columns/EditableCellDropCompanytype";
import { useExternalColumns } from "./externalColumn";
import { Styles } from "./table-css";
import "./external.css";

function External() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [CompanyName, SetCompanyName] = useState("");
  const { jd_id } = useParams();
  const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
  const [filteredData, setFilteredData] = useState([]);
  let tableHeadings = useExternalColumns(); 
  const [columns, setColumns] = useState(tableHeadings);// State to store the filtered data

  // Function to handle option selection
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  async function changeLog(
    sCID,
    statusFrom,
    statusTo,
    subStatusFrom,
    subStatusTo
  ) {
    console.log("Log substatus change as well");
    const data = {
      rows: [
        {
          selected_candidate_id: sCID,
          from_status: statusFrom,
          to_status: statusTo,
          from_substatus: subStatusFrom,
          to_substatus: subStatusTo,
          external_flag: true,
        },
      ],
    };
    await insertIntoTable("status_log", data);
  }

  // Fetch Data from DB
  async function FetchCandidates(jd_id) {
    var tableName = "selectedcandidatesjdview";
    console.log(selectedOption)
    if(!selectedOption){
      const { result, error } = await loadTableData(tableName, {
        jd_id: jd_id,
        internal_status: "yes", // Use the selected option as the filter value
      });
      
      if (result?.length) {
        setData(result);
        setLoading(false);
      }
    }
    const { result, error } = await loadTableData(tableName, {
      jd_id: jd_id,
      internal_status: "yes",
      external_status: selectedOption, // Use the selected option as the filter value
    });
 if (result?.length>=0) {
      console.log("External");
      console.log(result);
      setData(result);
      setLoading(false);
    }
  }

  async function GetCompanyName(jd_id) {
    var tableName = "jd_data_details";
    const { result, error } = await loadTableData(tableName, { jd_id: jd_id });
    if (result?.length) {
      console.log(" Company");
      console.log(result);
      SetCompanyName(result.length > 0 ? result[0] : undefined);
    }
  }

  // Update data to DB
  async function UpdateTable(columnId, CID, JID, value) {
    const rows = {
      rows: [
        {
          update: { [columnId]: value },
          match: { candidate_id: CID, jd_id: JID },
        },
      ],
    };
    await updateTableData("selected_candidates", rows);
  }

  async function UpdateTableCandiates(columnId, mobile, value) {
    const rows = {
      rows: [{ update: { [columnId]: value }, match: { mobile: mobile } }],
    };
    await updateTableData("candidates", rows);
  }

  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (["external_status", "external_sub_status"].includes(columnId)) {
            UpdateTable(columnId, row.candidate_id, row.jd_id, value);
          } else {
            UpdateTableCandiates(columnId, row.mobile, value);
          }
          return { ...old[rowIndex], [columnId]: value };
        }
        return row;
      })
    );
    setLoading(false);
  };

  

  const userType = window.sessionStorage.getItem("UserType");
  if (userType === "true") {
    tableHeadings = tableHeadings.filter(
      (head) => head.Header !== "Internal Detail"
    );
  }



  useEffect(() => {
    FetchCandidates(jd_id);
  }, [jd_id,selectedOption]);
  useEffect(() => {
    let reorderedTableHeadings = [];
    const storedTableHeadingsOrder = JSON.parse(localStorage.getItem("tableHeadingsOrder"));
    console.log("Stored order table",storedTableHeadingsOrder)
    if (storedTableHeadingsOrder) {
      reorderedTableHeadings = storedTableHeadingsOrder.map((headingObj) =>
    tableHeadings.find((heading) => heading.Header === headingObj.Header)
  );
  console.log("Reorder table",reorderedTableHeadings);
  // setColumns(tableHeadings);
  setColumns(reorderedTableHeadings);
    } else{
      setColumns(tableHeadings);
    }
  }, []);

  const notify = (notification) =>
    toast.error(notification, { position: toast.POSITION.TOP_CENTER });

  const link = "/internal/" + jd_id;

  const sortedFn = { sortBy: [{ id: "shortlist_date", desc: true }] };

  useEffect(() => {
    GetCompanyName(jd_id);
  }, []);

  return (
    <>
      <Header
        link={link}
        linktype={"EXTERNAL"}
        data={data}
        CompanyName={CompanyName}
      />
      <Styles>
        <div>
          {loading ? (
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              wrapperClass="blocks-wrapper"
              colors={["#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd"]}
            />
          ) : (data.length !== 0?
            <div style={{width:"100%"}}>
              <div className="container">
                {/*<nav className="navbar">
                  <ul className="navbar-list">
                    <li
                      className={`navbar-item ${
                        selectedOption === "awaiting screening from client"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleOptionClick("awaiting screening from client")
                      }
                    >
                      Awaiting Screening from client
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "assignment round" ? "active" : ""
                      }`}
                      onClick={() => handleOptionClick("assignment round")}
                    >
                      Assignment Round
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "first round" ? "active" : ""
                      }`}
                      onClick={() => handleOptionClick("first round")}
                    >
                      First Round
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "middle stage round 1"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleOptionClick("middle stage round 1")}
                    >
                      Middle Stage Round 1
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "middle stage round 2"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleOptionClick("middle stage round 2")}
                    >
                      Middle Stage Round 2
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "final stage round" ? "active" : ""
                      }`}
                      onClick={() => handleOptionClick("final stage round")}
                    >
                      Final Stage Round
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "documentation/negotiation"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleOptionClick("documentation/negotiation")
                      }
                    >
                      Documentation/Negotiation
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "post offer followup" ? "active" : ""
                      }`}
                      onClick={() => handleOptionClick("post offer followup")}
                    >
                      Post Offer Followup
                    </li>
                    <li
                      className={`navbar-item ${
                        selectedOption === "rejected" ? "active" : ""
                      }`}
                      onClick={() => handleOptionClick("rejected")}
                    >
                      Rejected
                    </li>
                  </ul>
                    </nav>*/}

                {/* Render your filtered data */}
                <div>
                  {filteredData.map((item) => (
                    <div key={item.id}>
                      {/* Display your filtered data */}
                      <p>{item.name}</p>
                      {/* Additional content */}
                    </div>
                  ))}
                </div>
              </div>
              <Table
                columns={columns}
                data={data}
                updateData={updateData}
                changeLog={changeLog}
                notify={notify}
                sortedFn={sortedFn}
                pageType={"External"}
                callFetchCandidatesMethod={FetchCandidates}
              />
            </div>:""
          )}
          <ToastContainer />
        </div>
      </Styles>
      <Footer />
    </>
  );
}

export default External;
