import React, { useState, useEffect } from "react";

import { loadTableData, insertIntoTable, updateTableData } from "../api";
import {
  sendMail,
  sendWhatsAppMsg,
  downloadResume,
  locationCities,
} from "../utility";
import { ToastContainer, toast } from "react-toastify";
import { Comments } from "./comments";
import { UserStatus } from "./userStatus";
import { CurrentJDS } from "./currentJds";
import { ProfileView } from "./profileView";
import {Interview} from "../interview/interview";

export function ProfileDetail({
  rowValue,
  pageType,
  currentIndex,
  updatedDetail,
  activeTab,
}) {
  const defaultSkillsArr = [
    { env: "", exp: "" },
    { env: "", exp: "" },
    { env: "", exp: "" },
    { env: "", exp: "" },
  ];
  const [profDetail, setProfDetail] = useState(rowValue || {});
  const [selectedTab, setSelectedTab] = useState(activeTab);
  const [selectedCities, setSelectedCities] = useState("");
  const [expYear, setExpYear] = useState(0);
  const [expMonth, setExpMonth] = useState(0);
  const [skills, setSkills] = useState(defaultSkillsArr);
  const [LWDDate, setLWDDate] = useState(null);
  const [userStatus, setUserStatus] = useState([]);
  const [selectedFile, setSelectedFile] = React.useState();
  const hiddenFileInput = React.useRef(null);
  const BASEURL = process.env.REACT_APP_BASE_URL;
  const url = `${BASEURL}resume/${profDetail.candidate_id}`;

  const loadProfileData = async () => {
    if (profDetail.skill) {
      const updatedSkills = [...profDetail.skill, ...skills];
      updatedSkills.length = 4;
      setSkills(updatedSkills);
    }
    if (profDetail.total_exp) {
      const years = Math.floor(profDetail.total_exp / 12);
      setExpYear(years);
      const months = profDetail.total_exp % 12;
      setExpMonth(months);
    }
    if (profDetail.last_working_day) {
      const lwd = new Date(profDetail.last_working_day);
      setLWDDate(lwd);
    }
  };

  const fetchUserStatus = async () => {
    try {
      console.log(profDetail.selected_candidate_id);
      const { result, error } = await loadTableData("status_log", {
        selected_candidate_id: profDetail.selected_candidate_id,
      });
      setUserStatus(result);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchUserJDS = async () => {
    try {
      console.log(profDetail);
      const { result, error } = await loadTableData("candidatejdmapping", {
        candidate_id: profDetail.id,
      });
      console.log(result);
      setUserStatus(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedTab === "ProfileView") {
      loadProfileData();
    } else if (selectedTab === "UserStatus") {
      fetchUserStatus();
    } else if (selectedTab === "CurrentJDS") {
      fetchUserJDS();
    }
    //set default tab
    openSelectedTab(selectedTab);
  }, [selectedTab]);

  async function changeLog(
    sCID,
    statusFrom,
    statusTo,
    subStatusFrom,
    subStatusTo,
    external
  ) {
    //console.log("Log substatus change as well")
    const user_id = window.sessionStorage.getItem("UserID");
    const data = {
      rows: [
        {
          selected_candidate_id: sCID,
          from_status: statusFrom,
          to_status: statusTo,
          from_substatus: subStatusFrom,
          to_substatus: subStatusTo,
          external_flag: external,
        },
      ],
    };
    await insertIntoTable("status_log", data);
  }

  async function updateCompany(id, value) {
    const rows = { rows: [{ update: { type: value }, match: { id: id } }] };
    await updateTableData("company", rows);
  }

  const updateProfileDetail = (evt, statusType) => {
    let colName = evt.target.name;
    let colVal = evt.target.value;
    const pd = { ...profDetail };
    console.log("colName", colVal);
    switch (colName) {
      case "email":
        pd.email = colVal;
        break;
      case "status":
        if (statusType === "internal") {
          colName = "internal_status";
          pd.internal_status = colVal;
        } else {
          colName = "external_status";
          pd.external_status = colVal;
        }
        break;
      case "sub_status":
        if (statusType === "internal") {
          colName = "internal_sub_status";
          pd.internal_sub_status = colVal;
        } else {
          colName = "external_sub_status";
          pd.external_sub_status = colVal;
        }
        break;
      case "remark":
        pd.remark = colVal;
        break;
      case "current_ctc_float":
        pd.current_ctc_float = colVal;
        break;
      case "min_expected_ctc_float":
        pd.min_expected_ctc_float = colVal;
        break;
      case "notice_period_days":
        pd.notice_period_days = colVal;
        break;
      case "notice_period_status":
        pd.notice_period_status = colVal;
        break;
      case "holding_offer":
        pd.holding_offer = colVal;
        break;
      case "company_type":
        pd.company_type = colVal;
        updateCompany(colName, colVal);
        break;
      case "holding_ctc":
        pd.holding_ctc = colVal;
        break;
      case "designation":
        pd.designation = colVal;
        break;
      case "job_prefer_location":
        pd.job_prefer_location = colVal;
        break;
      case "job_prefer_type":
        pd.job_prefer_type = colVal;
        break;
      case "hike":
        pd.hike = colVal;
        break;
      default:
        console.log("no case match");
    }
    setProfDetail(pd);
    updatedDetail({ index: currentIndex, id: colName, value: colVal });
    var external = true;
    if (statusType === "internal") {
      external = false;
    }
    if (colName === "internal_status") {
      changeLog(
        profDetail.selected_candidate_id,
        profDetail.internal_status,
        colVal,
        profDetail.internal_sub_status,
        profDetail.internal_sub_status,
        false
      );
    } else if (colName === "external_status") {
      changeLog(
        profDetail.selected_candidate_id,
        profDetail.external_status,
        colVal,
        profDetail.external_sub_status,
        profDetail.external_sub_status,
        true
      );
    } else if (colName === "internal_sub_status") {
      changeLog(
        profDetail.selected_candidate_id,
        profDetail.internal_status,
        profDetail.internal_status,
        profDetail.internal_sub_status,
        colVal,
        false
      );
    } else if (colName === "external_sub_status") {
      changeLog(
        profDetail.selected_candidate_id,
        profDetail.external_status,
        profDetail.external_status,
        profDetail.external_sub_status,
        colVal,
        true
      );
    }
  };

  const updateLWD = (date) => {
    setLWDDate(date);
    updatedDetail({ index: currentIndex, id: "last_working_day", value: date });
  };

  const updateCollege = (i, c, v) => {
    updatedDetail({
      index: currentIndex,
      id: "college_name_highest_degree",
      value: v,
    });
  };
  useEffect(() => {
    //console.log(profDetail)
  });

  const updateCompnay = (i, c, v) => {
    updatedDetail({ index: currentIndex, id: "current_company", value: v });
  };

  const locationUpdate = (e, colname) => {
    const cityStr = e.map((city) => city.value).toString();
    setSelectedCities(cityStr);
    const pd = { ...profDetail };
    if (colname === "current_location") {
      pd.current_location = cityStr;
    } else {
      pd.job_prefer_location = cityStr;
    }
    setProfDetail(pd);
    updatedDetail({
      index: currentIndex,
      id: colname,
      value: cityStr,
    });
  };

  const renderSelectedLocations = (cityArr) => {
    var items = cityArr ? cityArr.split(",") : [];
    return items.map((city) => ({ value: city, label: city }));
  };
  const cityOptions = locationCities();

  const renderExpValue = (exp) => {
    if (!exp) return "";
    const years = Math.floor(exp / 12);
    const months = exp % 12;
    return years + "." + months;
  };

  const updateSkill = (evt, index) => {
    const colName = evt.target.name;
    let colVal = evt.target.value;
    if (colName === "exp") {
      const exp = colVal.split(".");
      const yrs = parseInt(exp[0] * 12, 10);
      const mths =
        exp[1] === "" || exp[1] === undefined ? 0 : parseInt(exp[1], 10);
      colVal = yrs + mths;
    }
    skills[index][colName] = colVal;
    setSkills(skills);
    updatedDetail({ index: currentIndex, id: "skill", value: skills });
  };

  const counts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const renderSelectOption = (label) => {
    const options = counts.map((number) => (
      <option key={number} value={number}>
        {number + " " + label}
      </option>
    ));
    return options;
  };

  const updateExpDropdown = (e) => {
    const colName = e.target.name;
    const colVal = parseInt(e.target.value);
    switch (colName) {
      case "year":
        setExpYear(colVal);
        calculateExp(colName, colVal);
        break;
      case "month":
        setExpMonth(colVal);
        calculateExp(colName, colVal);
        break;
      default:
        console.log("case not match");
    }
  };

  const calculateExp = (colName, colVal) => {
    let exp;
    if (colName === "year") {
      exp = colVal * 12 + expMonth;
    } else {
      exp = expYear * 12 + colVal;
    }
    updatedDetail({ index: currentIndex, id: "total_exp", value: exp });
  };

  const openSelectedTab = (tabName) => {
    setSelectedTab(tabName);
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
  };
  const changeHandler = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    handleSubmission(selectedFile);
  };

  const openFileChooser = () => {
    hiddenFileInput.current.click();
  };

  const handleSubmission = async (selectedFile) => {
    if (!selectedFile) {
      toast.error("Please select file", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    //console.log(rowValue)
    const BASEURL = process.env.REACT_APP_BASE_URL;
    const url = `${BASEURL}resume/${rowValue.candidate_id}`;
    //console.log(selectedFile)
    const formdata = new FormData();
    formdata.append("resume", selectedFile);

    var requestOptions = {
      method: "POST",
      body: formdata,
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response?.status !== 200) {
        throw new Error("Server responds with error!");
      }
      setSelectedFile("");
      const updatedRow = { ...profDetail, resume_link: response.url };
      setProfDetail(updatedRow);
      toast.success("Uploaded Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      return response.text();
    } catch (err) {
      toast.error(String(err), { position: toast.POSITION.TOP_CENTER });
      //console.log(err);
    }
  };

  const renderStatusDate = (userdt) => {
    //console.log("date", userdt);
    let strDate = "";
    if (userdt) {
      const dt = new Date(userdt);
      strDate = dt.getDate() + "-" + dt.getMonth() + "-" + dt.getFullYear();
    }
    return strDate;
  };

  return (
    <div
      className="modall-content"
      style={{
        width: "800px",
        height: "calc(100vh - 100px)",
        overflow: "scroll",
      }}
    >
      <div className="modall-header">Profile Detail</div>
      <div className="modall-body mb-2" style={{ border: "1px solid #000" }}>
        {/*Tab links */}
        <div className="tab">
          <button
            className="tablinks"
            onClick={() => openSelectedTab("ProfileView")}
          >
            Profile View
          </button>
          <button
            className="tablinks"
            onClick={() => openSelectedTab("UserStatus")}
          >
            User Status
          </button>
          <button
            className="tablinks"
            onClick={() => openSelectedTab("Remarks")}
          >
            Comments
          </button>
          {(pageType === "Internal" || "External" )?
           <button
           className="tablinks"
           onClick={() => openSelectedTab("Interview")}
         >
           Interview
         </button>:""
          }
          
          {pageType === "Candidate" ? (
            <button
              className="tablinks"
              onClick={() => openSelectedTab("CurrentJDS")}
            >
              Active JDS
            </button>
          ) : (
            ""
          )}
        </div>

        {/* Tab content */}
        <div id="ProfileView" className="tabcontent">
          <ProfileView
            profDetail={profDetail}
            updateProfileDetail={updateProfileDetail}
            updateCollege={updateCollege}
            currentIndex={currentIndex}
            locationUpdate={locationUpdate}
            cityOptions={cityOptions}
            pageType={pageType}
            renderSelectedLocations={renderSelectedLocations}
            updateCompnay={updateCompnay}
            updateSkill={updateSkill}
            renderExpValue={renderExpValue}
            changeHandler={changeHandler}
            hiddenFileInput={hiddenFileInput}
            openFileChooser={openFileChooser}
            LWDDate={LWDDate}
            expMonth={expMonth}
            updateExpDropdown={updateExpDropdown}
            updateLWD={updateLWD}
            expYear={expYear}
            renderSelectOption={renderSelectOption}
          />
        </div>

        <div
            id="Interview"
            className="tabcontent"
            style={{ overflow: "auto", maxHeight: "400px" }}
          >
            <Interview data={rowValue} />
          </div>

        <div id="UserStatus" className="tabcontent">
          <UserStatus
            userStatus={userStatus}
            renderStatusDate={renderStatusDate}
          />
        </div>
        <div id="Remarks" className="tabcontent">
          <Comments data={rowValue} />
        </div>

        {pageType === "Candidate" ? (
          <div id="CurrentJDS" className="tabcontent">
            <CurrentJDS currentJds={userStatus} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
