import React from "react";

export function ColumnFilterForInternalExp({
  column: { filterValue, preFilteredRows, setFilter },
  JdCompanyName,
}) {
  const count = preFilteredRows.length;
  // console.log(JdCompanyName);
  return (
    <div>
      <input
        style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }} // Set undefined to remove the filter entirely
        placeholder={`Search ${count} records...`}
        onClick={(e) => e.stopPropagation()}
      />
      <div style={{ border: "1px solid green" }}>
        {/* <span style={{color:"red", fontSize:"100%",paddingBottom:"5px"}}>ReQ:{' '}</span> */}
        <span style={{ color: "red" }}>MustSkill: </span>
        <span>{JdCompanyName.must_skill}</span>
        <span style={{ color: "red" }}> Exp: </span>
        <span>{JdCompanyName.skill_wise_exp}</span>
      </div>
    </div>
  );
}
