import React from "react";
import { ToastContainer, toast } from "react-toastify";

const BASEURL = process.env.REACT_APP_BASE_URL;
export const ResumeDowmload = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
}) => {
  const [rowData, setRowData] = React.useState(data[index]);
  const [selectedFile, setSelectedFile] = React.useState();
  const hiddenFileInput = React.useRef(null);

  //const url = `${BASEURL}pdf-viewer/${rowData.candidate_id}`;
  const url = `/pdf-viewer/${rowData.candidate_random_id}`;

  const changeHandler = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    handleSubmission(selectedFile);
  };

  const openFileChooser = () => {
    hiddenFileInput.current.click();
  };

  const handleSubmission = async (selectedFile) => {
    if (!selectedFile) {
      toast.error("Please select file", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }

    const formdata = new FormData();
    formdata.append("resume", selectedFile);

    var requestOptions = {
      method: "POST",
      body: formdata,
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response?.status !== 200) {
        throw new Error("Server responds with error!");
      }
      setSelectedFile("");
      const updatedRow = { ...rowData, resume_link: response.url };
      setRowData(updatedRow);
      toast.success("Uploaded Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      return response.text();
    } catch (err) {
      toast.error(String(err), { position: toast.POSITION.TOP_CENTER });
      //console.log(err);
    }
  };

  return rowData.resume_link ? (
    <a
      href={url}
      // download="candidate.pdf"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        style={{
          backgroundColor: "DodgerBlue",
          color: "white",
          cursor: "pointer",
          margin: "2px",
        }}
      >
        Download
      </button>
    </a>
  ) : (
    <div>
      <input
        type="file"
        name={"UserResume"}
        id={"userresume"}
        title="UserResume"
        accept=".pdf"
        ref={hiddenFileInput}
        onChange={changeHandler}
        style={{ display: "none" }}
      />
      <button
        style={{
          backgroundColor: "DodgerBlue",
          color: "white",
          cursor: "pointer",
          margin: "2px",
        }}
        onClick={openFileChooser}
      >
        Upload
      </button>
    </div>
  );
};
