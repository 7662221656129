import React from "react";
import { useParams } from "react-router-dom"; 
import { ProfileDetail } from "../profile_details/profileDetail";
import Modal from "../components/modal/Modal";

export const EditableCellName = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
  updateData, // This is a custom function that we supplied to our table instance
  pageType,
  callFetchCandidatesMethod,
}) => {
  const rowData = data[index];
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [isOpen, setIsOpen] = React.useState(false);
  const { jd_id } = useParams();

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const updatedDetail = (params) => {
    updateData(params.index, params.id, params.value);
  };

  return (
    <div>
      <div
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => setIsOpen(true)}
      >
        {value || ""}
      </div>
      <Modal
        handleClose={() => {
          setIsOpen(false);
          callFetchCandidatesMethod(jd_id);
          //window.location.reload();
        }}
        isOpen={isOpen}
      >
        <ProfileDetail
          rowValue={rowData}
          pageType={pageType}
          currentIndex={index}
          updatedDetail={updatedDetail}
          activeTab={pageType === "Candidate" ? "UserStatus" : "ProfileView"}
        />
      </Modal>
    </div>
  );
};
