import React, { useState, useEffect } from "react"; 
import { useNavigate } from 'react-router-dom';


const CandidateLargeBasic = (data) => {
  const navigate = useNavigate();

  const onShortlistButton=()=>{

  }

  const onRejectButton=()=>{

  }
  const onCandidateSelect=()=>{
    navigate("/candidate-details/")
  }

  return (

    <div>
            {  console.log(data.data)}
    <div className="candidate-short-container" onClick={onCandidateSelect}>  
    <h1>{data.data["name"]} {data.name}</h1>
    <h2> Basic Info</h2>
        <div>
        <span>{data.data["designation"]}</span> at <span>{data.data["funding"]}</span> startup
        </div>
        <div>
        <ul>
        {data.data["tags"].map((tag)=> (
        <li key={tag}>
          <span>{tag}</span>
        </li>
        ))}
        </ul>
        </div>
    <h2> Compensation</h2>
    <div className="nice-table-container">
      <table className="nice-table">
        <thead>
          <tr>
            <th>Information</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Current CTC</td>
            <td>25 LPA</td>
          </tr>
          <tr>
            <td>Expected Hike</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>Predicted CTC</td>
            <td>35 LPA</td>
          </tr>
        </tbody>
      </table>
      <a href='/compensation'>Check on Compensation Meter</a>
    </div>
    </div>
    </div>
  );
};

export default CandidateLargeBasic;
