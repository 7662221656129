import React from "react";

export const EditableCellDropInternalStatus = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  changeLog,
  data,
}) => {
  const [value, setValue] = React.useState(initialValue);
  //const [preStatus, setPreStatus] = React.useState(value);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(e.target.value);
    updateData(index, id, newValue);
    changeLog(data[index].selected_candidate_id, value, newValue, "", "");
    //setPreStatus(e.target.value);
  };

  // const onBlur = (e) =>{
  //   const newValue = e.target.value;
  //   setValue(newValue);
  // }

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <select value={value || "new"} onChange={onChange} /*onBlur={onBlur}*/>
      <option value="new">New</option>
      <option value="yes">Yes</option>
      <option value="maybe">Maybe </option>
      <option value="no">No</option>
      <option value="could not connect_1">Could Not Connect_1</option>
      <option value="could not connect_2">Could Not Connect_2</option>
      <option value="could not connect_3">Could Not Connect_3</option>
    </select>
  );
};
