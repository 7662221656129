import React from "react";

export function ColumnFilterForInternal({
  column: { filterValue, preFilteredRows, setFilter, id },
  JdCompanyName,
}) {
  const count = preFilteredRows.length;
  var req;
  if (id === "job_prefer_location") {
    req = JdCompanyName.job_location;
  } else {
    req = JdCompanyName.college_preference;
  }
  // console.log(JdCompanyName);
  return (
    <div>
      <input
        style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }} // Set undefined to remove the filter entirely
        placeholder={`Search ${count} records...`}
        onClick={(e) => e.stopPropagation()}
      />
      <div style={{ border: "1px solid green" }}>
        <span style={{ color: "red", fontSize: "100%", paddingBottom: "5px" }}>
          ReQ:{" "}
        </span>
        <span>{req}</span>
      </div>
    </div>
  );
}
