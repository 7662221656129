import React from "react";

export function Skills({ profDetail, updateSkill, renderExpValue }) {
  return (
    <>
      <div className="col-12">
        {/* Skill 1 */}
        <div className="row">
          <div className="col-4">
            <label>Skills</label>
          </div>
          <div className="col-4">
            <input
              type="text"
              name="env"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[0] &&
                profDetail.skill[0]["env"]
              }
              onBlur={(e) => updateSkill(e, 0)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              name="exp"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[0] &&
                renderExpValue(profDetail.skill[0]["exp"])
              }
              onBlur={(e) => updateSkill(e, 0)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <label>y.m</label>
          </div>
        </div>

        {/* Skill 2 */}
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <input
              type="text"
              name="env"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[1] &&
                profDetail.skill[1]["env"]
              }
              onBlur={(e) => updateSkill(e, 1)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              name="exp"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[1] &&
                renderExpValue(profDetail.skill[1]["exp"])
              }
              onBlur={(e) => updateSkill(e, 1)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <label>y.m</label>
          </div>
        </div>

        {/* Skill 3 */}
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <input
              type="text"
              name="env"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[2] &&
                profDetail.skill[2]["env"]
              }
              onBlur={(e) => updateSkill(e, 2)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              name="exp"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[2] &&
                renderExpValue(profDetail.skill[2]["exp"])
              }
              onBlur={(e) => updateSkill(e, 2)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <label>y.m</label>
          </div>
        </div>

        {/* Skill 4 */}
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <input
              type="text"
              name="env"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[3] &&
                profDetail.skill[3]["env"]
              }
              onBlur={(e) => updateSkill(e, 3)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              name="exp"
              defaultValue={
                profDetail.skill &&
                profDetail.skill[3] &&
                renderExpValue(profDetail.skill[3]["exp"])
              }
              onBlur={(e) => updateSkill(e, 3)}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <label>y.m</label>
          </div>
        </div>
      </div>
    </>
  );
}
