import React, { useState, useEffect } from "react"; 
import Header from "../header";
import CandidateLargeBasic from "./candidateLargeBasic";
import CandidateLargeResume from "./candidateLargeResume";
import CandidateLargeScreen from "./candidateLargeScreen";
import CustomPDFViewer from "../ResumeView/CustomPDFViewer";


const data = {
    name : "Rohan Joshi",
    designation : "Senior Software Developer",
    funding : "Series D",
    tags : ["HTML", "CSS", "Javascript"]
}

const activeTab = "Basic"

const CandidateLarge = () => {
  const [selectedTab, setSelectedTab] = useState(activeTab);

  const onShortlistButton=()=>{

  }

  const onRejectButton=()=>{

  }
  const onCandidateSelect=()=>{
    Navigate("/candidate-details/")
  }

    useEffect(() => {

    openSelectedTab(selectedTab);
  }, [selectedTab]);

  const openSelectedTab = (tabName) => {
    setSelectedTab(tabName);
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
  };

  return (
    <div>

    <Header link={""} linktype={"PDF"} data={""} CompanyName={""} />
    <div className="search-text-container">
    
    <div className="modall-body mb-2" style={{ border: "1px solid #000" }}>
      {/*Tab links */}
      <div className="tab">
        <button
          className="tablinks"
          onClick={() => openSelectedTab("Basic")}
        >
          Basic
        </button>
        <button
          className="tablinks"
          onClick={() => openSelectedTab("Resume")}
        >
          Resume
        </button>
        <button
          className="tablinks"
          onClick={() => openSelectedTab("Screen")}
        >
          Screen
        </button>
     </div>

      {/* Tab content */}
      <div id="Basic" className="tabcontent">
        <CandidateLargeBasic data={data}/>
     </div>
     <div id="Resume" className="tabcontent">
        <CustomPDFViewer/>
     </div>
     <div id="Screen" className="tabcontent">
        <CandidateLargeScreen data={data}/>
     </div>
    </div>
    <div className="button-layer">
      <button className="reject-button" onClick={onRejectButton}>Drop Candidate</button>
      <button className="submit-button" onClick={onShortlistButton}>Send to Dash Screen</button>
    </div>
  </div>
  </div>
  );
};

export default CandidateLarge;
