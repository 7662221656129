import React from "react";

export const EditableCellRangeNotice = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  notify,
}) => {
  //  console.log("from here",initialValue,index)
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    //  var InputValue = parseInt(e.target.value, 10);

    setValue(e.target.value);
  };
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (value < -1 || value >> 90) {
      notify("Notic Period must be between 0-10 !");
    } else {
      // console.log("from above",value,index)
      updateData(index, id, value);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div>
      <input
        style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
        type="number"
        id={index}
        value={value === 0 ? "0" : value || undefined}
        name={index}
        min={1}
        max={200}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
