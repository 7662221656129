import React, { useState } from "react";
import Modal from "../components/modal/Modal";
import InterviewModal from "../modals/interviewModal";
import { loadTableData } from "../api";

export const Interview = (props) => {
  const [interviews, setInterviews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newInterview, setNewInterview] = useState({
    title: "",
    date: new Date(),
    startTime: "08:00 AM", // Default start time
    endTime: "09:00 AM", // Default end time
    interviewers: [],
    description: "",
    status: "",
    comments: "",
  });
  const { data } = props;
  console.log("Interview", data);
  const handleAddInterview = () => {
    setIsModalVisible(true);
    setInterviews([...interviews, newInterview]);
    setNewInterview({
      title: "",
      date: new Date(),
      startTime: "08:00 AM",
      endTime: "09:00 AM",
      interviewers: [],
      description: "",
      status: "",
      comments: "",
    });
  };

  React.useEffect(() => {
    const getInterview = async () => {
      const interviews = await loadTableData("interviews", {
        selected_candidate_id: data.selected_candidate_id,
      });
      console.log("Interviews", interviews);
    };
    getInterview();
  }, []);
  return (
    <div>
      {/* <form>
        <label>
          Title:
          <input
            type="text"
            name="title"
            value={newInterview.title}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          Date:
          <input
            type="date"
            name="date"
            value={newInterview.date}
            onChange={(e) => handleDateChange(e.target.value)}
          />
        </label>
        <br />
        <label>
          Start Time:
          <input
            type="text"
            name="startTime"
            value={newInterview.startTime}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          End Time:
          <input
            type="text"
            name="endTime"
            value={newInterview.endTime}
            onChange={handleInputChange}
          />
        </label>
        <br />
        {/* <label>
          Timezone:
          <TimezoneSelect onChange={handleTimezoneChange} />
        </label> */}
      {/* <br />
        <label>
          Description:
          <textarea
            name="description"
            value={newInterview.description}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          Status:
          <select
            name="status"
            value={newInterview.status}
            onChange={handleInputChange}
          >
            <option value="">Select Status</option>
            <option value="Scheduled">Scheduled</option>
            <option value="Completed">Completed</option>
            {/* Add other status options */}
      {/* </select>
        </label>
        <br />  */}
      <div>
        <button type="button" onClick={handleAddInterview}>
          Add Interview
        </button>
        <Modal
          isOpen={isModalVisible}
          handleClose={() => setIsModalVisible(false)}
        >
          <InterviewModal data={data} setIsModalVisible={setIsModalVisible} />
        </Modal>
      </div>
      {/* </form> */}
      <br />
      <h2>Interviews</h2>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Interviewers</th>
            <th>Description</th>
            <th>Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          no data
          {/* {interviews.map((interview, index) => (
            <tr key={index}>
              <td>{interview.title}</td>
              <td>{interview.date}</td>
              <td>{interview.startTime}</td>
              <td>{interview.endTime}</td>
              <td>
                {interview.interviewers.map((interviewer, i) => (
                  <span key={i}>{interviewer}</span>
                ))}
              </td>
              <td>{interview.description}</td>
              <td>{interview.status}</td>
              <td>{interview.comments}</td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
};

