import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
const BASEURL = process.env.REACT_APP_BASE_URL || 'https://app.pragti.in/internal-api-stage/';
const _ = require("lodash");

const notify = (notification) =>
  toast.success(notification, { position: toast.POSITION.TOP_CENTER });

export const sendMail = async (row) => {
  const googleCred = Cookies.get("gSign");

  const url = `${BASEURL}email/jd/${row.jd_id}`;

  const config = {
    method: "POST",
    url: url,
    headers: {
      Authorization: googleCred,
    },
    data: { candidates: [row.candidate_id] },
    mode: "no-cors",
  };
  try {
    const response = await axios(config);
    notify("Email Sent !!");
    return response?.status;
  } catch (err) {
    toast.success(`Server issue`, {
      position: toast.POSITION.TOP_CENTER,});
    console.log(err.message);
  }
};

export const updateTableData = (tableName,rows) => {
  const baseurl = `${BASEURL}table/${tableName}`;
  const url = baseurl;
  //console.log("udapting apis")
  //console.log(rows)
  const googleCred = Cookies.get("gSign");
  //console.log(googleCred)
  const config = 
  {
    method: "PUT",
    url: url,
    data: rows,
    headers: {
      Authorization: googleCred,
      "Content-Type": "application/json"
    },
    mode: "no-cors",
  }
  return makeApiCall(config);
};


export const loadTableData = (tableName, filters) => {
  let baseurl = `${BASEURL}table/${tableName}?`;
  
  /*if (filter?.id) {
    url = url + `id=${filter.id}`;
  } else if (filter?.name) {
    url = url + `_ilike.name=%${filter.name}%`;
  }*/
  var url = baseurl;
  if (Object.keys(filters).length > 0) {
    const searchParams = new URLSearchParams();
    for (const key in filters) {
      if (filters[key]) {
        searchParams.append(key, filters[key]);
      }
    }
    url = `${baseurl}${searchParams.toString()}`;
  }

  //console.log(url)
  const googleCred = Cookies.get("gSign");
  const config = {
    method: "GET",
    url: url,
    headers: {
      Authorization: googleCred,
    },
    mode: "no-cors",
  };
  return makeApiCall(config);
};

export const insertIntoTable = async (tableName, data) => {
  const googleCred = Cookies.get("gSign");

  const config = {
    method: "POST",
    url: `${BASEURL}table/${tableName}`,
    headers: {
      Authorization: googleCred,
    },
    data: data,
    mode: "no-cors",
  };
  return makeApiCall(config);
};

export const makeApiCallWithHeader = async (config) =>{
  const googleCred = Cookies.get("gSign");
  config["headers"] = "Authorization:" + googleCred
  return makeApiCall(config)
}


export const makeApiCall = async (config) => {
  try {
    const response = await axios(config);
      if(response.status === 200){
         return response.data;
       } else if(response.status === 401){
         toast.success(`Session expired`, {
           position: toast.POSITION.TOP_CENTER,
         });
       } else {
        toast.success(`Session expired. Please login again`, {
          position: toast.POSITION.TOP_CENTER,
        });
         toast.success(`Session expired. Please login again`, {
          position: toast.POSITION.TOP_CENTER,});
         return {status: response.status, message: "Some server error occured"};
       }
    
  } catch (err) {
    toast.success(`Server issue`, {
      position: toast.POSITION.TOP_CENTER,});
    console.log(err)
  }
};


export const makeApiCallFull = async (config) => {
  try {
    const response = await axios(config);
    if(_.size(response)>0){  
      return response;
    }
  } catch (err) {
    toast.success(`Server issue`, {
      position: toast.POSITION.TOP_CENTER,});
    console.log(err);
  }
};