import React, { useState } from "react";

export function Email({ rowValue, setOpenModal, updatedEmail }) {
  const [email, setEmail] = useState(rowValue || "");
 console.log(rowValue)
  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updateData = () => {
    updatedEmail(email);
    setOpenModal(false);
  };

  return (
    <div className="modall-content">
      <div className="modall-header">Email</div>
      <div className="modall-body">
        <div className="modall-body-row">
          <label>Email</label>
          <input
              type="text"
              value={email}
              onChange={(e) => updateEmail(e)}
              className="form-control"
            />
        </div>
      </div>
      <div className="modall-footer">
        <button onClick={() => setOpenModal(false)}>Cancel</button>
        <button onClick={updateData}>Save</button>
      </div>
    </div>
  );
}
