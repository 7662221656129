import React from 'react';
import { combinedOptions } from './statusSubstatusDictionary';
 
export function Status({ selectedKey,handleKeyChange,pageType, profDetail, updateProfileDetail }) {
  const selectedStatusKey = `${pageType.toLowerCase()}_status`;
  const handleStatusChange = (e) => {
    // Call both updateProfileDetail and handleKeyChange when the status changes
    updateProfileDetail(e, pageType.toLowerCase());
    handleKeyChange(e.target.value); // Pass the selected value to handleKeyChange
  };
  
  return (
    <>
      <div className="col-3 text-center">
        <label>Status</label>
      </div>
      <div className="col-3">
        <select
          name="status"
          value={profDetail[selectedStatusKey] || ""}
          className="form-control"
          onChange={handleStatusChange}
        >
          <option disabled={true} value="">
            Select
          </option>
          {combinedOptions[pageType].status.map((statusOption) => (
            <option key={statusOption} value={statusOption.toLowerCase()}>
              {statusOption}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
