import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import {
  loadTableData,
  insertIntoTable,
  makeApiCall,
  makeApiCallWithHeader,
  updateTableData,
} from "../api";
import { EditableCell } from "../columns/EditableCell";

import Table, {
  dateBetweenFilterFn,
} from "./Table";
import NewCandidatesRerquest from "../modals/new-candidates";
import { Styles } from "./table-css";
import { ColorRing } from "react-loader-spinner";
import { useColumns } from "./internalColumn";

function Internal() {
  const [source, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [JdCompanyName, SetJdCompanyName] = useState("");
  const { jd_id } = useParams();
  const [toogleCandidates, setToogleCandidates] = React.useState(false);

  async function FetchCandidates(jd_id) {
    var tableName = "selectedcandidatesjdview";
    const { result, error } = await loadTableData(tableName, { jd_id: jd_id });
    if (result?.length>=0) {
      setData(result);
      setLoading(false);
    }
  }

  async function GetJdCompanyName(jd_id) {
    var tableName = "jd_data_details";
    const { result, error } = await loadTableData(tableName, { jd_id: jd_id });
    if (result?.length) {
      console.log("Jd Company")
      console.log(result)
      SetJdCompanyName(result.length > 0 ? result[0] : undefined);
    }
  }

  async function UpdateTable(columnId, CID, JID, value) {
    const matchParams = {
      candidate_id: CID,
      jd_id: JID,
    };
    if (value === "yes") {
      const updateParams = {
        [columnId]: value,
        transition_time: new Date(),
      };
      const rows = { rows: [{ update: updateParams, match: matchParams }] };
      await updateTableData("selected_candidates", rows);
    } else {
      const updateParams = {
        [columnId]: value,
      };
      const rows = { rows: [{ update: updateParams, match: matchParams }] };
      await updateTableData("selected_candidates", rows);
    }
  }

  async function UpdateTableCandiates(columnId, mobile, value) {
    //console.log("Updating table")
    const rows = {
      rows: [{ update: { [columnId]: value }, match: { mobile: mobile } }],
    };
    //console.log(rows)
    await updateTableData("candidates", rows);
  }

async function addRemark(comment) {
    const data = { rows: [comment] };
    insertIntoTable("remarks", data);
  }
 
  async function changeLog(sCID, statusFrom, statusTo,subStatusFrom, subStatusTo) {
    const user_id = window.sessionStorage.getItem('UserID');
    const data = {rows:[{
      selected_candidate_id: sCID,
      from_status: statusFrom,
      to_status: statusTo,
      from_substatus : subStatusFrom,
      to_substatus: subStatusTo,
      user_id : user_id
    }]}

    await insertIntoTable("status_log", data);
  }

  async function updateCompany(id, value) {
    const rows = { rows: [{ update: { type: value }, match: { id: id } }] };
    await updateTableData("company", rows);
  }

 const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {        
        if (index === rowIndex) {
          if (
            ["internal_status", "internal_sub_status", "jd_sent"].includes(
              columnId
            )
          ) {
            UpdateTable(columnId, row.candidate_id, row.jd_id, value);
          } else if (columnId == "company_type") {
            updateCompany(row.company_id, value);
          } else {
            if (columnId === "college_name") {
              columnId = "college_name_highest_degree";
            } else if (columnId === "company_name") {
              columnId = "current_company";
            }
            UpdateTableCandiates(columnId, row.mobile, value);
          }

          return { ...old[rowIndex], [columnId]: value };
        }
        return row;
      })
    );
    setLoading(false);
  };

  dateBetweenFilterFn.autoRemove = (val) => !val;

  const columns = useColumns();

  useEffect(() => {
    FetchCandidates(jd_id);
  }, [jd_id]);

  const notify = (notification) =>
    toast.success(notification, { position: toast.POSITION.TOP_CENTER });

  const link = "/external/" + jd_id;

  const sortedFn = { sortBy: [{ id: "shortlist_date", desc: true }] };

  useEffect(() => {
    GetJdCompanyName(jd_id);
  }, []);

  return (
    <>
      {toogleCandidates ? (
        <NewCandidatesRerquest setToogleCandidates={setToogleCandidates} />
      ) : (
        ""
      )}
      <Header
        link={link}
        linktype={"INTERNAL"}
        jd={jd_id}
        data={source}
        JdCompanyName={JdCompanyName}
        setToogleCandidates={setToogleCandidates}
      />
      <Styles>
        <div>
          {loading ? (
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              wrapperClass="blocks-wrapper"
              colors={["#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd"]}
            />
          ) : (source.length !== 0?
            <Table
              columns={columns}
              data={source}
              updateData={updateData}
              JdCompanyName={JdCompanyName}
              notify={notify}
              changeLog={changeLog}
              addRemark={addRemark}
              sortedFn={sortedFn}
              pageType={"Internal"}
              callFetchCandidatesMethod={FetchCandidates}
            />:""
          )}
          <ToastContainer />
        </div>
      </Styles>
      <Footer />
    </>
  );
}

export default Internal;
