import React from "react";

export function NumberRangeColumnFilterInternal({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  JdCompanyName,
}) {
  var req1;
  var req2;
  switch (id) {
    case "min_expected_ctc_float":
      req1 = JdCompanyName.min_ctc;
      req2 = JdCompanyName.max_ctc;
      break;
    case "total_exp":
      req1 = JdCompanyName.min_exp;
      req2 = JdCompanyName.max_exp;
      break;
    case "notice_period_days":
      req1 = 0;
      req2 = JdCompanyName.max_notice_period;
      break;
  }
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div>
        <input
          style={{ margin: "2px", maxWidth: "80px", fontSize: "0.85rem" }}
          value={filterValue[0]}
          type="number"
          onChange={(e) => {
            var val = e.target.value;
            if (val.length <= 0) {
              val = -1;
            }
            setFilter((old = []) => [
              val > -1 ? parseInt(val, 10) : undefined,
              old[1] === undefined ? 0 : old[1],
            ]);
          }}
          placeholder={`Min (${min})`}
          onClick={(e) => e.stopPropagation()}
        />
        <div style={{ border: "1px solid green" }}>
          <span
            style={{ color: "red", fontSize: "100%", paddingBottom: "5px" }}
          >
            ReQ:{" "}
          </span>
          <span>{req1}</span>
        </div>
      </div>
      to
      <div>
        <input
          style={{ margin: "2px", maxWidth: "80px", fontSize: "0.85rem" }}
          value={filterValue[1]}
          type="number"
          onChange={(e) => {
            var val = e.target.value;
            if (val.length <= 0) {
              val = -1;
            }
            setFilter((old = []) => [
              old[0],
              val > -1 ? parseInt(val, 10) : undefined,
            ]);
          }}
          placeholder={`Max (${max})`}
          onClick={(e) => e.stopPropagation()}
        />
        <div style={{ border: "1px solid green" }}>
          <span
            style={{ color: "red", fontSize: "100%", paddingBottom: "5px" }}
          >
            ReQ:{" "}
          </span>
          <span>{req2}</span>
        </div>
      </div>
    </div>
  );
}
