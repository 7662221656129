import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import HomePage from './homepage';
import App from './App';
import Internal from './tableView/internal';
import External from './tableView/external';
import ResumeViewer from './ResumeView/ResumeViewer';
import JdView from './tableView/jd';
import Welcome from './welcome';
import ProtectedRoute from './auth';
import Cookies from 'js-cookie'
import CompensationMeter from './CompensationMeter';
import Settings from './settings';
import SearchPage from './candidateSearch/searchPage';
import CandidateList from './candidateSearch/candidateList';
import CandidateLarge from './candidateSearch/candidateLarge';
//import 'semantic-ui-css/semantic.min.css'

// import jwtDecode from 'jwt-decode';

const UserContext = React.createContext(null);

export default function Router() {
  var user  = Cookies.get("gSign", {path:"/"})
  return (
    <BrowserRouter>
    <UserContext.Provider value={user}>
      <Routes>
        <Route index  path="/" element={<HomePage/>}/>
        <Route element={<ProtectedRoute/>}>
          <Route path="/candidates" element={<App/>} />
          <Route path="/settings" element={<Settings/>}/>
          <Route path="/compensation" element={<CompensationMeter/>} />
          <Route path="internal/:jd_id/" element={<Internal/>} />
          <Route path="external/:jd_id/" element={<External/>} />
          <Route path="jd" element={<JdView/>} />
          <Route path="welcome" element={<Welcome/>} />
          <Route exact path="/pdf-viewer/:id" element={<ResumeViewer/>} />
          //Routes for Dubai Demo
          <Route exact path="/search-page/" element={<SearchPage/>} />
          <Route exact path="/candidate-list/" element={<CandidateList/>} />
          <Route exact path="/candidate-details/:id" element={<CandidateLarge/>} />
        </Route>
      </Routes>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);

