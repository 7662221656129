import React,{useEffect,useState} from "react";
import { FaUserCircle } from "react-icons/fa";
import { insertIntoTable,loadTableData } from "../api";
import "./remarks.css";

export function Comments({ data}) {
  const [comments, setComments] = useState([]);
  const [text, setText] = useState('');

    const updateData = async (comment) => {
        if(data.selected_candidate_id){
          comment["selected_candidate_id"] = data.selected_candidate_id;
          const updatedData = { rows: [comment] };
          await insertIntoTable("remarks", updatedData);
          FetchRemarks(data.selected_candidate_id);
        }else if(data.jd_id){
          comment["jd_id"]=data.jd_id;
          const updatedData = { rows: [comment] };
          await insertIntoTable("jd_remarks", updatedData);
          FetchJDRemarks(data.jd_id);
        }   
      };

      useEffect(() => {
        // Fetch remarks when the component mounts or when `data` changes
        console.log("Jd Data",data)
        if (data?.selected_candidate_id) {
          FetchRemarks(data.selected_candidate_id);
        } else if(data?.candidate_id){
          FetchRemarks(data.candidate_id)
        } else if(data?.jd_id){
          FetchJDRemarks(data.jd_id)
        }
      }, [data]);

      async function FetchRemarks(sCID) {
        var tableName = "comment_details"
        const { result, error } = await loadTableData(tableName, {"selected_candidate_id":sCID});
        if (result?.length) {
            console.log("Result",result)
          setComments(result);
        }
      }

      async function FetchJDRemarks(sCID) {
        var tableName = "jd_remarks"
        const { result, error } = await loadTableData(tableName, {"jd_id":sCID});
        if (result?.length) {
            console.log("Result",result)
          setComments(result);
        }
      }
   

    const handleSubmit = (event) => {
        event.preventDefault();
        const user_id = window.sessionStorage.getItem('UserID');
        const newComment = { user_id: user_id,comment: text};
        updateData(newComment)
        newComment["created_at"] = new Date().toISOString().slice(0, 10) ;
        setComments([...comments, newComment]);
        setText("");
      };
  return (
    <>
    <div style={{padding:"10px",maxHeight:"400px",overflowY:"scroll"}}>
    <div>
        <textarea
          label="Comment"
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
        <button type="submit" onClick={handleSubmit}>
          Add Comment
        </button>
      </div>
    <div className="thread-column">
      {comments?comments.map((message, index) => (
        <div key={index} className="message-row">
          <div className="user-image">
            <FaUserCircle size={40} className="white-icon" />
          </div>
          <div className="message-content">
            <div className="comment-header">
            <div>{message.user_name || "Unknown User"}</div>
            <div className="timestamp">{message.created_at || "Unknown Date"}</div>
            </div>
            <div className="message-text">{message.comment || "No Comment"}</div>
          </div>
        </div>
      )):""}
    </div>
    </div>
    </>
  );
}
