import React from "react";

export function HoldingOffer({profDetail,updateProfileDetail}) {
    console.log("Holding Offer",profDetail.holding_offer);
  return (
    <>
      <div className="col-4">
        <label>Holding Offer</label>
      </div>
      <div className="col-8">
        <select
          name="holding_offer"
          value={
            profDetail.holding_offer === null
              ? "notselected"
              : profDetail.holding_offer
              ? "true"
              : "false"
          }
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        >
          <option disabled={true} value="">
            Select
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
          <option value="notselected">Not Selected</option>
        </select>
      </div>
    </>
  );
}
