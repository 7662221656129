import React from "react";
import Multiselect from "multiselect-react-dropdown";

export function PreferredLocation({profDetail,renderSelectedLocations,cityOptions,locationUpdate}) {
  return (
    <>
      <div className="col-4">
        <label>Preferred Location</label>
      </div>
      <div className="col-8">
        <Multiselect
          style={{ minHeight: 35, width: 300 }}
          name="job_prefer_location"
          selectedValues={renderSelectedLocations(profDetail.job_prefer_location)}
          options={cityOptions}
          displayValue="value"
          onSelect={(e) => locationUpdate(e, "job_prefer_location")}
          onRemove={(e) => locationUpdate(e, "job_prefer_location")}
        />
      </div>
    </>
  );
}
