import React from "react";
import Table, {
  JdCompany,
  LinksExternal,
  LinksInternal,
  ResumeLink,
  SelectColumnFilter,
  EditableCell,
  EditableCellDropCompanytype,
  EditableCellNumber,
  EditableCellDropLocation,
  EditableCellJobType,
  EditableCellDropCollegePreference,
  EditableCellDropGoodToHave,
  EditableCellSwitch,
  EditableCellToggle,
} from "./Table";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import { Styles } from "./table-css";
import NewJD from "../modals/new-jd";
import Footer from "../footer";
import {insertIntoTable, loadTableData,updateTableData} from "../api";
import { ColorRing } from "react-loader-spinner";
import { useJdColumns } from "./jdColumn";


function JdView() {
  const [loading,setLoading]=useState(true);
  const [data, setData] = useState([]);
  const [toggleNewJD, setToggleNewJD] = React.useState(false);

  // Fetch Data from DB
  async function FetchJD() {
    var tableName = "jd_data_details"
    const { result} = await loadTableData(tableName, {});
    if (result?.length>=0) {
      //console.log("JD")
      //console.log(result)
      setData(result);
      setLoading(false);
    }
  }

  // Update data to DB
  async function UpdateTable(columnId, id, value) {
    const rows = {
      rows: [{ update: { [columnId]: value }, match: { id: id} }],
    };
    await updateTableData("jd_data", rows);
  }

  async function InsertJD(companyID, designation) {
    const data = {rows:[{ company: companyID, designation: designation, active: true }]};
    await insertIntoTable("jd_data",data);
      FetchJD();
  }

  // Update data after data change
  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          UpdateTable(columnId, row.jd_id, value);
          return { ...old[rowIndex], [columnId]: value };
        }
        return row;
      })
    );
    setLoading(false);
  };

  const userType = window.sessionStorage.getItem('UserType');
  

  const columns = useJdColumns();

  if(userType === "true"){
    columns = columns.filter((head) => head.Header !== "Internal Detail");
  }

  useEffect(() => {
    FetchJD();
  }, []);
  const notify = (notification) =>
    toast.error(notification, { position: toast.POSITION.TOP_CENTER });

  return (
    <>
      {toggleNewJD ? (
        <NewJD setToggle={setToggleNewJD} InsertJD={InsertJD} />
      ) : (
        ""
      )}
      <Header linktype={"JD"} setToggle={setToggleNewJD} />
      <Styles>
        <div>
          {loading?(
             <ColorRing
             visible={true}
             height="80"
             width="80"
             ariaLabel="blocks-loading"
             wrapperStyle={{position:"fixed",top:"50%",left:"50%",transform:"translate(-50%, -50%)"}}
             wrapperClass="blocks-wrapper"
             colors={["#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd"]}
           />
          ):(data.length !== 0?<Table
            columns={columns}
            data={data}
            updateData={updateData}
            notify={notify}
          />:"")}
          <ToastContainer />
        </div>
      </Styles>
      <Footer />

    </>
  );
}

export default JdView;
