import React, { useState, useEffect } from "react"; 
import CandidateShort from "./candidateShort";
import Header from "../header";


const candidates = [
    {
        name : "Rohan Joshi",
        designation : "Senior Software Developer",
        funding : "Series D",
        tags : ["HTML", "CSS", "Javascript"]

    },
    {
        name : "Tanmay Bhat",
        designation : "Backend Developer",
        funding : "Series A",
        tags : ["HTML", "CSS", "Javascript"]

    }
]

const CandidateList = () => {

  return (
    <div className="search-text-container">
    <Header link={""} linktype={"PDF"} data={""} CompanyName={""} />
    <div>
    {candidates.map((candidate) => (
        <CandidateShort data={candidate}/>
    ))}  
    </div>
    </div>
  );
};

export default CandidateList;
