import React, { useState, useEffect } from "react"; 
import { useNavigate } from 'react-router-dom';
import MarksDisplay from "./MarksDisplay";

const skills = [
      {
        "skill":"Data Structures and Algorithms",
        "marks" : 35
      },
      {
        "skill":"System Design",
        "marks" : 67
      },
      {
        "skill":"Low Level Design",
        "marks" : 89
      },
      {
        "skill":"Soft Skills",
        "marks" : 95
      }
]

const CandidateLargeScreen = (data) => {
  const navigate = useNavigate();

  const onShortlistButton=()=>{

  }

  const onRejectButton=()=>{

  }
  const onCandidateSelect=()=>{
    navigate("/candidate-details/")
  }

  return (

    <div>
            {  console.log(data.data)}
    <div className="candidate-short-container" onClick={onCandidateSelect}>  
    <div>
    <h2>Round 1</h2>
    <div>{data.data["name"]} {data.name}</div>
        <div>
        <span>{data.data["designation"]}</span> at <span>{data.data["funding"]}</span> startup
        </div>
    </div>
    <div>
    <h2>Skills</h2>
    {skills.map((skill) => (
        <MarksDisplay data={skill}/>
    ))}  
    </div> 

    <div>
    <h2>Interviewer's Remark</h2>
    <h4> Strengths</h4>
    <ul>
      <li>The candidate was able to solve both SQL problems</li>
      <li>The candidate has good hands in SQL</li>
      <li>He has good quantitative aptitude as he was really quick with solving of the quant problem</li>
      <li>He has good analytical and logical thinking as he was able to mention maximum points during the business case study discussion.</li>
      <li>The candidate looks like a good fit for the data analyst role as he is able to solve SQL problems, has a good understanding of businesses, good with mathematical questions. I would recommend here him.</li>
    </ul>

    <h4>Areas to Improve</h4>
    <ul>
      <li>The Candidate's communication can be improved. Since he was fumbling a lot</li>
    </ul>
    </div>
    </div>
    </div>
  );
};

export default CandidateLargeScreen;
