import React, { useState } from "react";
import Modal from "../components/modal/Modal";
import Table from "../tableView/Table";
import { SkillModal } from "../modals/skillModal";
import JdInterviewModal from "../modals/jdInterviewModal";
import { loadTableData } from "../api";

const initialRow = {
    interviewType: "",
    interviewPOC: [],
    comments: "",
  };
const EditableInterview = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rows, setRows] = useState([initialRow]);
  
  const handleAddInterview = async() => {
    setIsModalVisible(true);
  };

  return (
    <div>
      <button type="button" onClick={handleAddInterview}>
        Add Interview
      </button>
      <Modal
        isOpen={isModalVisible}
        handleClose={() => setIsModalVisible(false)}
      >
        <JdInterviewModal
          rowValue={data[index]}
          rows={rows}
          setRows={setRows}
          setIsModalVisible={setIsModalVisible}
        />
      </Modal>
    </div>
  );
};

export default EditableInterview;
