import React from "react";
export const EditableCellRange = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData,
    notify,
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      // var InputValue = parseInt(e.target.value, 10);
      setValue(e.target.value);
    };
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      if (value < 0 || value > 200) {
        notify("CTC must be between 0-200 !");
      } else {
        updateData(index, id, value);
      }
    };
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <div style={{ margin: "3px" }}>
        <input
          style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
          type="number"
          id={index}
          value={value || undefined}
          name={index}
          min={0}
          max={200}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    );
  };
  