import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { loadTableData,insertIntoTable } from "../api";

export const DashAutoComplete = (props) => {
  const { tableName, index, id, updateData, selectedValue,onBlur,intialValue } = props;
  //const useEffVar = selectedValue?.name || "";
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState(intialValue);
  const [filterText, setFilterText] = useState("");
  const wrapperRef = useRef(null);

  const loadDropdownData = async (InputValue) => {
    const response = await loadTableData(tableName,{"_ilike.name":`%${InputValue}%`})
    console.log(response)
    const data = response.result;
    //console.log(data)
    if (data.length) {
      setDisplay(true);
      setOptions(data);
    } else {
      setDisplay(true);
      setOptions([]);
    }
  };

  useEffect(() => {
    setSearch(intialValue);
  }, [intialValue]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const addNewEntry = async () => {
    if(!filterText.length) return;
    //insert in company
    const { result} = await insertIntoTable(tableName,{"rows":[{ name: search }]})
      // .from(tableName)
      // .insert([{ name: search }]);
    //console.log("post insert")
    //console.log(result)
    setDisplay(false);
    setFilterText("");
    
    updateData(index, id, result[0]);

    //succes msg
    toast.success(`${tableName} added successfully`, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const callParentMethod = (obj) => {
    setSearch(obj.name);
    setDisplay(false);
    if(index === 0 && id === 0){
        updateData(obj.id);
    } else {
        updateData(index, id, obj.id);
    }
  };

  const filterDropdown = (value) => {
    setSearch(value);
    setFilterText(value);
    if(value.length>2){
      console.log("Valueeeeeee")
      loadDropdownData(value);
    }
    if (value === "") {
      // Clear previous value
      setSearch(""); // Clear the search state
      if (index === 0 && id === 0) {
        updateData(null);
      } else {
        updateData(index, id, null);
      }
    }
  };

  return (
    <div ref={wrapperRef} className="flex-container flex-column pos-rel">
      <textarea
        id="auto"
        onClick={() => setDisplay(!display)}
        placeholder="Search..."
        value={search}
        style={{ overflow: "auto" }}
        onChange={(event) => filterDropdown(event.target.value)}
      />
      {display && (
        <div
          className="autoContainer"
          style={{
            position: "absolute",
            zIndex: 9,
            background: "white",
            width: "20%",
            textAlign: "left",
            maxHeight: "250px",
            overflow: "scroll",
            cursor: "pointer",
            border: "1px solid #dbdbdb",
            boxShadow: "1px 2px 6px #aaaaaa",
          }}
        >
          {options.length ? (
            options.map((value, i) => (
              <div
                onClick={() => callParentMethod(value)}
                className="option"
                style={{
                  padding: "5px",
                  borderBottom: "1px solid #dbdbdb",
                }}
                key={i}
                tabIndex="0"
              >
                <span>{value.name}</span>
              </div>
            ))
          ) : (
            <div
              onClick={() => addNewEntry()}
              className="option"
              style={{
                padding: "5px",
                cursor: "pointer",
                borderBottom: "1px solid #dbdbdb",
              }}
            >
              <span>Add New</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
