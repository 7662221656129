import React from "react";

export function Designation({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Designation</label>
      </div>
      <div className="col-8">
        <input
          type="text"
          name="designation"
          value={profDetail.designation}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        />
      </div>
    </>
  );
}
