import React from "react";

export const EditableCellDropInternalSubStatus = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  changeLog,
  data,
}) => {
  const option1 = ["Interested"];
  const option2 = ["Sent JD"];
  const option3 = [
    "High CTC expected",
    "6 days work week",
    "Location not suitable",
    "Not looking for job change",
    "Already in progress with this company",
    "Not fit for the role due to designation/ seniority / skill",
    "Company brand / Company culture/ Company size / Company a startup",
    "Not ready to WFO",
  ];
  const option4 = [
    "Asked to call later",
    "Called but did not pick",
    "Not Reachable",
    "Wrong Number",
    "Busy",
  ];
  const [value, setValue] = React.useState(initialValue);
  const [preStatus, setPreStatus] = React.useState(value);

  const internalStatus = data[index].internal_status;
  const onChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
    updateData(index, id, e.target.value);
    changeLog(
      data[index].selected_candidate_id,
      "",
      "",
      preStatus,
      e.target.value
    );
    setPreStatus(e.target.value);
  };

  // const onBlur = (e) =>{
  //   updateData(index, id, e.target.value);
  // }

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  var finalOptions = "";
  if (internalStatus === "yes") {
    finalOptions = option1.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (internalStatus === "no") {
    finalOptions = option3.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (internalStatus === "maybe") {
    finalOptions = option2.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  } else if (
    internalStatus === "could not connect" ||
    internalStatus === "could not connect_1" ||
    internalStatus === "could not connect_2" ||
    internalStatus === "could not connect_3"
  ) {
    finalOptions = option4.map((item, index) => (
      <option key={index} value={item.toLowerCase()}>
        {item}
      </option>
    ));
  }
  return (
    <select
      style={{ maxWidth: "80px" }}
      value={value || ""}
      onChange={onChange}
      /*onBlur={onBlur}*/
    >
      <option value="" disabled>
        Select
      </option>
      {finalOptions}
    </select>
  );
};
