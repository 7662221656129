import React from "react";

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        style={{ margin: "2px", maxWidth: "80px", fontSize: "0.85rem" }}
        value={filterValue[0]}
        type="number"
        onChange={(e) => {
          var val = e.target.value;
          if (val.length <= 0) {
            val = -1;
          }

          setFilter((old = []) => [
            val > -1 ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        onClick={(e) => e.stopPropagation()}
      />
      to
      <input
        style={{ margin: "2px", maxWidth: "80px", fontSize: "0.85rem" }}
        value={filterValue[1]}
        type="number"
        onChange={(e) => {
          var val = e.target.value;
          if (val.length <= 0) {
            val = -1;
          }
          setFilter((old = []) => [
            old[0],
            val > -1 ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
}
