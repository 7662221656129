import React from 'react';

export function CurrentJDS({currentJds}){
    return(
        <>
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          border: "1px solid #ccc",
          fontWeight: "bold",
        }}
      >
        <span style={{ width: "33%", textAlign: "center" }}>
          Company Name
        </span>
        <span style={{ width: "33%", textAlign: "center" }}>
          Job Id
        </span>
        <span style={{ width: "33%", textAlign: "center" }}>
          Designation
        </span>
      </div>
      {currentJds?.length > 0 ? (
        currentJds.map((row) => (
          <div
            key={row.jd_id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              border: "1px solid #ccc",
            }}
          >
            <span style={{ width: "33%", textAlign: "center" }}>
              {row.jd_company_name}
            </span>
            <span style={{ width: "33%", textAlign: "center" }}>
              {row.jd_id}
            </span>
            <span style={{ width: "33%", textAlign: "center" }}>
              {row.designation}
            </span>
          </div>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            border: "1px solid #ccc",
          }}
        >
          <span>No records found</span>
        </div>
      )}  
      </> 
    )
}