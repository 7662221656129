import React from "react";

export function NoticePeriod({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Notice Period</label>
      </div>
      <div className="col-8">
        <input
          type="text"
          name="notice_period_days"
          value={profDetail.notice_period_days}
          className="form-control"
          style={{
            width: "75%",
            display: "inline",
            marginRight: "10px",
          }}
          onChange={(e) => updateProfileDetail(e)}
        />
        <label>Days</label>
      </div>
    </>
  );
}
