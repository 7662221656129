import React from "react";

export const EditableCellExp = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [expYear, setExpYear] = React.useState(0);
    const [expMonth, setExpMonth] = React.useState(0);
  
    const counts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  
    const renderSelectOption = (label) => {
      const options = counts.map((number) => (
        <option key={number} value={number}>
          {number + " " + label}
        </option>
      ));
      return options;
    };
  
    const initExpDrowdown = (initVal) => {
      const expYr = Math.floor(initVal / 12);
      setExpYear(expYr);
      const expMth = initVal % 12;
      setExpMonth(expMth);
    };
  
    const updateExpDropdown = (e) => {
      const colName = e.target.name;
      const colVal = parseInt(e.target.value);
      switch (colName) {
        case "year":
          setExpYear(colVal);
          calculateExp(colName, colVal);
          break;
        case "month":
          setExpMonth(colVal);
          calculateExp(colName, colVal);
          break;
        default:
          console.log("case not match");
      }
    };
  
    const calculateExp = (colName, colVal) => {
      let exp;
      if (colName === "year") {
        exp = colVal * 12 + expMonth;
      } else {
        exp = expYear * 12 + colVal;
      }
      UpdateExp(exp);
    };
  
    const UpdateExp = (exp) => {
      updateData(index, id, exp);
    };
  
    React.useEffect(() => {
      setValue(initialValue);
      initExpDrowdown(initialValue);
    }, [initialValue]);
  
    if (!expYear && !expMonth) {
      return null;
    } else {
      return (
        <div style={{ display: "flex" }}>
          <select name="year" value={expYear} onChange={updateExpDropdown}>
            <option value="0" disabled>
              0 Year
            </option>
            {renderSelectOption("Year")}
          </select>
          <select name="month" value={expMonth} onChange={updateExpDropdown}>
            <option value="0" disabled>
              0 Month
            </option>
            {renderSelectOption("Month")}
          </select>
        </div>
      );
    }
  };
  