import React from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function Confirm({ setOpenModal, SendMailTrigger }) {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [html,setHtml]=React.useState();


    const updateData = () => {
    SendMailTrigger(html);
    setOpenModal(false);
  };

   // Handle editor state change
   const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  // Save editor content when needed (e.g., when the modal is closed)
  const saveEditorContent = () => {
    const contentState = editorState.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    console.log(contentRaw);
    setHtml(contentRaw.blocks)
     // You can save this content to your database or use it as needed.
  };

  return (
    <div className="modall-content">
      <div className="modall-header"></div>
      <div className="modall-body">
      <div>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <button onClick={saveEditorContent}>Save</button>
        <div className="modall-body-row">
          Do you want send JD to candidate ?
        </div>
      </div>
      <div className="modall-footer">
        <button onClick={() => setOpenModal(false)}>No</button>
        <button onClick={updateData}>Yes</button>
      </div>
    </div>
  );
}
