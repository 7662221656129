import React, { useState } from "react";
import { insertIntoTable, updateTableData } from "../api";

export function POCModal({
  onClose,
  action,
  newPOC,
  setNewPOC,
  setAction,
  rowValue,
}) {
  const [newRow, setNewRow] = useState({
    name: "", // Initial value for skill type
    designation: "", // Initial value for keyword
    email: "",
    mobile: "", // Initial value for years of experience
  });
  console.log("RowValue", rowValue);
  const handleChange = (e,index) => {
    const { name, value } = e.target;
    const updatedRows = [...newPOC];
    updatedRows[index][name] = value;
    setNewPOC(updatedRows);
  };

  const handleAddPOC = async (row) => {

      const data = {
        rows: [
          {
            jd_id: rowValue.jd_id,
            name: row.name,
            designation: row.designation,
            email: row.email,
            mobile: row.mobile,
          },
        ],
      };
      const response = await insertIntoTable("jd_poc", data);
      setNewPOC([...newPOC,{...newRow}])
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h2>Add POC</h2>
      </div>
      <div>
        <table
          style={{
            width: "100%",
            margin: "20px 0",
          }}
        >
          <thead>
            <tr style={{ height: "30px" }}>
              <th style={{ border: "1px solid #ddd" }}>Name</th>
              <th style={{ border: "1px solid #ddd" }}>Designation</th>
              <th style={{ border: "1px solid #ddd" }}>Email</th>
              <th style={{ border: "1px solid #ddd" }}>Mobile</th>
              <th style={{ border: "1px solid #ddd" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {newPOC.map((row, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid #ddd" }}>
                  {" "}
                  <input
                    type="text"
                    name="name"
                    value={row.name}
                    onChange={(e)=>handleChange(e,index)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd" }}>
                  {" "}
                  <input
                    type="text"
                    name="designation"
                    value={row.designation}
                    onChange={(e)=>handleChange(e,index)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd" }}>
                  {" "}
                  <input
                    type="text"
                    name="email"
                    value={row.email}
                    onChange={(e)=>handleChange(e,index)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd" }}>
                  {" "}
                  <input
                    type="text"
                    name="mobile"
                    value={row.mobile}
                    onChange={(e)=>handleChange(e,index)}
                  />
                </td>
                <td style={{ border: "1px solid #ddd" }}>
                  {index === newPOC.length - 1 && (
                    <button
                      variant="outlined"
                      onClick={() => handleAddPOC(row)}
                    >
                      Add
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div>
        <button
          style={{
            marginRight: "1rem",
            borderRadius: "8px",
            width: "5rem",
            backgroundColor: "#17E5C3",
          }}
          onClick={handleAddSkill}
        >
          {action === "Edit" ? "Edit" : "Add"}
        </button>
        <button
          style={{
            fontSize: "16px",
            borderRadius: "8px",
            width: "5rem",
            backgroundColor: "#17E5C3",
          }}
          onClick={onClose}
        >
          Cancel
        </button>
      </div> */}
    </div>
  );
}
