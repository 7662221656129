import React, { useEffect } from "react";
import { insertIntoTable,loadTableData } from "../api";

export function Skill({ rowValue, setOpenModal, UpdateSkils }) {
  const counts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const renderSelectOption = (label) => {
    const options = counts.map((number) => (
      <option key="number" value={number}>
        {number + " " + label}
      </option>
    ));
    return options;
  };

  const [frameworks, setFrameworks] = React.useState([]);
  const [value, setValue] = React.useState(rowValue ? rowValue : []);
  const [frmwork, setFrmwork] = React.useState("");
  const [expYear, setExpYear] = React.useState(0);
  const [expMonth, setExpMonth] = React.useState(0);
  const [addToggle, setAddToggle] = React.useState(true);
  const [newFtoggle, setNewFtoggle] = React.useState(false);
  const [newF, setNewF] = React.useState("");

  const min = 1;
  const max = 100;
  const rand = min + Math.random() * (max - min);

  async function NewFramework(name) {
    const data = {rows:[{ name: name }]}
    await insertIntoTable("skills",data);
  }

  async function getFramework() {
    
    const { data} =  await loadTableData("skills", {});
    setFrameworks(data.map((framework) => framework.name));
  }
  useEffect(() => {
    getFramework();
  }, []);

  const optionsFrame = frameworks.map((framework, index) => (
    <option key={index}>{framework}</option>
  ));

  const frmworkOnchange = (e) => {
    setFrmwork(e.target.value);
    setAddToggle(false);
  };

  const expOnchangeYear = (e) => {
    setExpYear(parseInt(e.target.value));
  };

  const expOnchangeMonth = (e) => {
    setExpMonth(parseInt(e.target.value));
  };

  const deleteSkills = (index) => {
    setValue([...value.slice(0, index), value.slice(index + 1)]);
    var OriginalItem = [...value];
    OriginalItem.splice(index, 1);
    setValue(OriginalItem);
  };

  const covertToyear = (exp) => {
    var numberAsInt = parseInt(exp, 10);
    var quotient = Math.floor(numberAsInt / 12);
    var remainder = numberAsInt % 12;
    return [quotient, remainder];
  };
  const covertTomonths = (year, months) => {
    var yearInt = parseInt(year, 10);
    var monthsInt = parseInt(months, 10);
    var totalMonths = yearInt * 12 + monthsInt;
    return totalMonths.toString();
  };

  const handleChange = (e) => {
    setNewF(e.target.value);
  };
  const handleSubmit = (e) => {
    NewFramework(newF);
    frameworks.push(newF);
    setFrameworks(frameworks);
    setNewFtoggle(false);
  };

  const skillWithDetail = Array.isArray(value) ? (
    value.map((item, index) => {
      if (!Array.isArray(item)) {
        return (
          <tr key={index + rand}>
            <td>{item["env"]}</td>
            <td>{covertToyear(item["exp"])[0]}</td>
            <td>{covertToyear(item["exp"])[1]}</td>
            <td>
              <button onClick={() => deleteSkills(index)}>Delete</button>
            </td>
          </tr>
        );
      }
      return <tr></tr>;
    })
  ) : (
    <tr></tr>
  );

  const SetValueOnAdd = () => {
    const expAll = covertTomonths(expYear, expMonth);
    var dict = { env: frmwork, exp: expAll };
    setValue([...value, dict]);
  };

  const updateData = () => {
    UpdateSkils(value);
    setOpenModal(false);
  };

  return (
    <div className="modall-content">
      <div className="modall-header">Skills</div>
      <div className="modall-body">
        {/* list framework */}
        <div className="modall-body-row">
          <label>Framework</label>
          {newFtoggle ? (
            <div style={{display: 'flex'}}>
              <input
                type="text"
                placeholder="Enter Framework"
                className="form-control"
                onChange={handleChange}
              />
              <button onClick={handleSubmit}>Confirm</button>
            </div>
          ) : (
            <div style={{display: 'flex'}}>
              <select
                defaultValue={"DEFAULT"}
                className="form-control"
                onChange={frmworkOnchange}
              >
                <option disabled value="DEFAULT">
                  Select Framework
                </option>
                {optionsFrame}
              </select>
              <button onClick={() => setNewFtoggle(true)} style={{whiteSpace: 'nowrap'}}>Add New</button>
            </div>
          )}
        </div>
        {/* list exp dropdown */}
        <div className="modall-body-row">
          <label>Experience</label>
          <select
            defaultValue={"DEFAULT"}
            onChange={expOnchangeYear}
            className="form-control"
          >
            {/* <option value="0">0 Year</option> */}
            {renderSelectOption("Year")}
          </select>
          <select
            defaultValue={"DEFAULT"}
            onChange={expOnchangeMonth}
            className="form-control"
          >
            {/* <option value="0">0 Month</option> */}
            {renderSelectOption("Month")}
          </select>
        </div>
        <div className="modall-body-row" style={{justifyContent: 'center'}}>
          <button disabled={addToggle} onClick={SetValueOnAdd}>
            Add Skill
          </button>
        </div>
        {/* display selected skill */}
        <div className="modall-body-column">
          <label>Selected Skills</label>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Framework</th>
                <th>Years</th>
                <th>Months</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{skillWithDetail.length ? skillWithDetail : <td colSpan={4}>No data</td>}</tbody>
          </table>
        </div>
      </div>
      <div className="modall-footer">
        <button onClick={() => setOpenModal(false)}>Cancel</button>
        <button onClick={updateData}>Save</button>
      </div>
    </div>
  );
}
