import React, { useState, useEffect } from "react"; 
import { useNavigate } from 'react-router-dom';
import Header from "../header";
import "./candidateSearch.css";


const SearchPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState([]);

  const onTextChange=(e)=>{
    setSearchText(e.target.value)
    }

  const onButtonClick=()=>{
    console.log("Button Clicked")
    navigate(`/candidate-list`);
  }

  return (
    <div>
      <Header link={""} linktype={"PDF"} data={""} CompanyName={""} />
     <div className="search-text-container">
      <textarea 
        rows={5} 
        className={"large-text-input"}
        placeholder="Enter search text here">
      </textarea>
      <button className="submit-button submit-position" onClick={onButtonClick}>Search Candidates</button>
      </div>
    </div>
  );
};

export default SearchPage;
