import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Header from "./header";
import { useExternalColumns } from "./tableView/externalColumn";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function Settings() {
  const [selectedTab, setSelectedTab] = useState("External");
  const tableHeadings = useExternalColumns();

  // Load items from local storage or use default order if not found
  const initialItems =
    JSON.parse(localStorage.getItem("columnOrder")) ||
    tableHeadings.map((heading, index) => ({
      id: `${index + 1}`,
      content: heading.Header,
      visible: true, // Initially all items are visible
    }));

  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    // Save items to local storage whenever it changes
    localStorage.setItem("columnOrder", JSON.stringify(items));
  }, [items]);

  useEffect(() => {
    const reorderedTableHeadings = items
    .map((item) => {
      const heading = tableHeadings.find(
        (tableHeading) => tableHeading.Header === item.content
      );
      return { ...heading, visible: item.visible }; // Add visibility property
    })
    .filter((item) => !item.visible)

    // Save reordered tableHeadings to localStorage
    localStorage.setItem(
      "tableHeadingsOrder",
      JSON.stringify(reorderedTableHeadings)
    );
  }, [items, tableHeadings]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(reorderedItems);
  };

  const handleTabClick = (tabNumber) => {
    setSelectedTab(tabNumber);
  };

  const toggleVisibility = (item) => {
    const updatedItems = [...items];
    const index = updatedItems.findIndex((i) => i.id === item.id);
    updatedItems[index].visible = !item.visible;
    console.log("updated items",updatedItems)
    setItems(updatedItems);
  };

  return (
    <div style={{ width: "100%" }}>
      <Header linktype={""} setToggle={false} />
      <div
        style={{
          marginTop: "5rem",
          width: "50%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{ border: "1px solid #808080", borderRadius: "2px" }}
          onClick={() => handleTabClick("External")}
        >
          External
        </button>
        <button
          style={{ border: "1px solid #808080", borderRadius: "2px" }}
          onClick={() => handleTabClick("Internal")}
        >
          Internal
        </button>
        <button
          style={{ border: "1px solid #808080", borderRadius: "2px" }}
          onClick={() => handleTabClick("JD")}
        >
          JD
        </button>
        <button
          style={{ border: "1px solid #808080", borderRadius: "2px" }}
          onClick={() => handleTabClick("All Candidate")}
        >
          All Candidate
        </button>
      </div>
      {selectedTab === "External" && (
        <DragDropContext onDragEnd={onDragEnd}>
          <div>
            <h5 style={{ marginTop: "1rem", marginLeft: "0.5rem" }}>
              {" "}
              {selectedTab} Columns:
            </h5>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  style={{ height: "max-content" }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            margin: "8px",
                            padding: "8px",
                            border: "1px solid #ddd",
                            borderRadius: "2px",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {item.content}
                          <button
                            onClick={() => toggleVisibility(item)}
                            style={{ marginLeft: "8px" }}
                          >
                            {item.visible ? <VisibilityOffOutlinedIcon fontSize="small"/>:<VisibilityOutlinedIcon fontSize="small"/>  }
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
    </div>
  );
}

export default Settings;
