import React from 'react';

const MarksDisplay = ({ data }) => {
  const barWidth = (data.marks / 100) * 100; // Calculate the bar width based on the marks

  const barStyle = {
    width: `${barWidth}%`,
    backgroundColor: '#4caf50',
    height: '20px',
    borderRadius: '5px',
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <h3>{data.skill}: {data.marks}/100</h3>
      <div style={{ border: '1px solid #ccc', borderRadius: '5px', height: '20px' }}>
        <div style={barStyle}></div>
      </div>
    </div>
  );
};

export default MarksDisplay;
