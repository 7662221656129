import styled from "styled-components";

export const Styles = styled.div`
  padding: 0.3rem;
  margin-top:60px;
  position: relative;

  table {
    border-spacing: 0;
    border: 3px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      text-align: center;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      background-color: #fff;
      border: 1px solid black;
      content-align: center;

      :last-child {
        border-right: 1px;
      }
    }
  }
  `