import React from 'react';
import { insertIntoTable,loadTableData } from '../api';
import Modal from '../components/modal/Modal';

 function JdModal({selectedRows,jdData,isModalOpens,setIsModalOpens}){

    // const [isModalOpens, setIsModalOpens] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [jdDatas,setJdDatas]= React.useState(jdData);
    const [jdOriginalDatas,setJdOriginalDatas]= React.useState(jdData);

    React.useEffect(()=>{
     console.log(selectedRows,jdData)
    },[])
    
    function filterListByCompanyName(list, searchTerm) {
      // Create an empty array to store filtered items
      const filteredList = [];
    
      // Loop through the list of dictionaries
      for (const item of list) {
        // Check if the 'company_name' key includes the search term (case-insensitive)
        if (item.company_name.toLowerCase().includes(searchTerm.toLowerCase())) {
          // If it does, add the item to the filtered list
          filteredList.push(item);
        }
      }
    
      // Return the filtered list
      return filteredList;
    }

    async function handleSearch(searchTerm){
      //console.log(searchTerm)
      //console.log(jdData);
      setSearchTerm(searchTerm);
      if (searchTerm.trim() === "") {
        // If the search filter is empty, reset the table data to the original jdData
        console.log("No search term")
      } else {
        // If the search filter is not empty, filter the table data based on the search term
        const result = filterListByCompanyName(jdOriginalDatas,searchTerm)
        setJdDatas(result);
      }
    }

    const handleRowCheckboxChange = (rowId) => {
        const selectedIndex = selectedRowData.indexOf(rowId);
        let updatedSelectedRows = [...selectedRowData];
    
        if (selectedIndex === -1) {
          updatedSelectedRows.push(rowId);
        } else {
          updatedSelectedRows.splice(selectedIndex, 1);
        }
    
        setSelectedRowData(updatedSelectedRows);
      };
    
      const handleSelectAllChange = () => {
        if (selectAll) {
          setSelectedRowData([]);
        } else {
          const allRowIds = jdDatas.map((row) => row.id);
          setSelectedRowData(allRowIds);
        }
    
        setSelectAll(!selectAll);
      };

      const handleJdMove = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
          for (let j = 0; j < selectedRowData.length; j++) {
            console.log("selectedRows")
            console.log(selectedRows)
            const data = {
              rows: [
                { candidate_id: selectedRows[i].candidate_id, jd_id: selectedRowData[j], },
              ],
            };
            console.log("api data")
            console.log(data)
            const response = await insertIntoTable("selected_candidates", data);
            console.log("response")
            console.log(response)
          }
        }
        setIsModalOpens(true);
      };

return(
    <div
    style={{
      padding: "10px",
      maxHeight: "400px",
      overflowY: "scroll",
    }}
  >
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={async (e) => handleSearch(e.target.value)}
      />
      <button
        style={{
          backgroundColor: "#17E5C3",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "16px",
          fontStyle: "normal",
          width: "20%",
          height: "40px",
        }}
        onClick={handleJdMove}
      >
        Send
      </button>
      {isModalOpens && (
        <Modal handleClose={()=>setIsModalOpens(false)} isOpen={isModalOpens}>
          <div>
          <h4>Candidates are moved successfuly to the respective JDS</h4>
          </div>
        </Modal>
      )}
    </div>
    <div> * {selectedRows.length} candidates are selected</div>
    <table
      style={{
        borderCollapse: "collapse",
        border: "1px solid black",
        borderRight: "1px solid black",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              padding: "8px",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          </th>
          <th
            style={{
              padding: "8px",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            Company
          </th>
          <th
            style={{
              padding: "8px",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            Job Id
          </th>
          <th
            style={{
              padding: "8px",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              alignSelf: "center",
            }}
          >
            Designation
          </th>
        </tr>
      </thead>
      <tbody>
        {jdDatas.map((row) => {
          return (
            <tr key={row.id}>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedRowData.includes(row.id)}
                  onChange={() => handleRowCheckboxChange(row.id)}
                />
              </td>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {row.company_name}
              </td>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {row.jd_id}
              </td>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {row.designation}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
)
}

export default JdModal;