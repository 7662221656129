import React, { useEffect } from "react";
import { loadTableData } from "../api";

const ParserDetails = (props) => {

    const { id } = props.id;
    const [data, setData] = React.useState(null);

    useEffect(()=>{
        loadTableData("parser_data",{"candidate_id":props.id}).then(data => setData(data))
    },[])
    
    return (
        <div>
            <div>
                <b>Email: </b>
            {data ? data["result"][0]["email_id"]: ""}
            </div>
            <div>
                <b>Mobile: </b>
            {data ? data["result"][0]["mobile"]: ""}
            </div>
            <div>
                <b>Company: </b>
            {data ? JSON.stringify(data["result"][0]["extract_json"]["company"]): ""}
            </div>
            <div>
                <b>College: </b>
            {data ? JSON.stringify(data["result"][0]["extract_json"]["college"]): ""}
            </div>
            <div>
                <b>Designation: </b>
            {data ? JSON.stringify(data["result"][0]["extract_json"]["designation"]): ""}
            </div>
            <div>
                <b>Degree: </b>
            {data ? JSON.stringify(data["result"][0]["extract_json"]["degree"]): ""}
            </div>
            <div>
                <b>Skills: </b> 
            {data ? JSON.stringify(data["result"][0]["extract_json"]["skills"]): ""}
            </div>
            {/*data ? data["result"][0]["email_id"] : "No data"*/} 
        </div>
    )
}

export default ParserDetails;