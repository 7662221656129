import React from "react";
import Header from "./header";
import Footer from "./footer";

function Welcome() {
  return (
    <>
      <Header linktype={""} setToggle={false} />
      <div>You are not autorised to access page. Kindly contact admin.</div>
      <Footer />
    </>
  );
}

export default Welcome;
