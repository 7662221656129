import React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { insertIntoTable, loadTableData } from "../api";

dayjs.locale("en");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "tanmay@dashhire.co",
  "shubham@dashhire.co",
  "pratyushyadav@dashhire.co",
  "abinashjha@dashhire.co",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const InterviewModal = ({ data, setIsModalVisible }) => {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const [startValue, setStartValue] = React.useState(dayjs("2022-04-17T15:30"));
  const [endValue, setEndValue] = React.useState(dayjs("2022-04-17T15:30"));
  const [newInterview, setNewInterview] = React.useState({
    title: "",
    date: new Date(),
    startTime: "08:00 AM", // Default start time
    endTime: "09:00 AM", // Default end time
    interviewers: [],
    description: "",
    status: "",
    comments: "",
  });
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [selectedChips, setSelectedChips] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewInterview({ ...newInterview, [name]: value });
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleChipDelete = (chipToDelete) => {
    setSelectedChips((prevChips) =>
      prevChips.filter((chip) => chip !== chipToDelete)
    );
  };

  const handleAddInterview = async () => {
    const newInterviewData = {
      rows: [
        {
          selected_candidate_id: data.selected_candidate_id,
          title: newInterview.title,
          interview_date: value.format("YYYY-MM-DD"),
          start_time: startValue.format("HH:mm A"),
          end_time: endValue.format("HH:mm A"),
          guests: selectedChips,
          description: description,
          status: "", // Update this with the actual status value if needed
          feedback: "", // Update this with the actual comments value if needed
        },
      ],
    };
    await insertIntoTable("interviews", newInterviewData);
    // setInterviews([...interviews, newInterviewData]);

    setIsModalVisible(false);

    setNewInterview({
      title: "",
      date: new Date(),
      startTime: "08:00 AM",
      endTime: "09:00 AM",
      interviewers: [],
      description: "",
      status: "",
      comments: "",
    });

    setValue(dayjs("2022-04-17"));
    setStartValue(dayjs("2022-04-17T15:30"));
    setEndValue(dayjs("2022-04-17T15:30"));
    setSelectedChips([]);
    setDescription("");
  };
  return (
    <div>
      <form>
        <input
          type="text"
          name="title"
          value={newInterview.title}
          onChange={handleInputChange}
          placeholder="Add Title"
          style={{ width: "20rem", borderRadius: "3px" }}
        />
        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Choose Date"
            value={value}
            onChange={(newValue) => setValue(newValue)}
            orientation="landscape"
            format="DD MMMM, YYYY"
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Start Time"
            value={startValue}
            onChange={(newValue) => setStartValue(newValue)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="End Time"
            value={endValue}
            onChange={(newValue) => setEndValue(newValue)}
          />
        </LocalizationProvider>
        <br />
        <br />
        <Autocomplete
          multiple
          id="multiselect-chips"
          options={names}
          value={selectedChips}
          onChange={(event, newValue) => setSelectedChips(newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Select Chips" />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                label={option}
                onDelete={() => handleChipDelete(option)} // This deletes the chip
                {...getTagProps({ index })}
              />
            ))
          }
        />
        <br />
        <br />
        <TextField
          label="Description"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={description}
          onChange={handleDescriptionChange}
        />
        <br/>
        <br/>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddInterview}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default InterviewModal;
