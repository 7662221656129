import { internalSubStatusOption1,internalSubStatusOption2,internalSubStatusOption3,internalSubStatusOption4,
externalSubStatusOption1,externalSubStatusOption2,externalSubStatusOption3,externalSubStatusOption4,externalSubStatusOption5,externalSubStatusOption6
 } from "./constants";
export const combinedOptions = {
    Internal: {
      status: [
        'new',
        'yes',
        'maybe',
        'no',
        'could not connect_1',
        'could not connect_2',
        'could not connect_3'
      ],
      subStatus: {
        'yes': internalSubStatusOption1,
        'no': internalSubStatusOption2,
        'maybe': internalSubStatusOption3,
        'could not connect_1': internalSubStatusOption4,
        'could not connect_2':internalSubStatusOption4,
        'could not connect_3':internalSubStatusOption4,
        'new':internalSubStatusOption4
      }
    },
    External: {
      status: [
        'awaiting screening from client',
        'assignment round',
        'first round',
        'middle stage round 1',
        'middle stage round 2',
        'final stage round',
        'documentation/negotiation',
        'post offer followup',
        'Rejected'
      ],
      subStatus: {
        'awaiting screening from client': externalSubStatusOption5,
        'assignment round': externalSubStatusOption4,
        'first round': externalSubStatusOption3, // Include this option
        'middle stage round 1': externalSubStatusOption3, // Include this option
        'middle stage round 2': externalSubStatusOption3, // Include this option
        'final stage round': externalSubStatusOption3, // Include this option
        'documentation/negotiation': externalSubStatusOption2,
        'post offer followup': externalSubStatusOption1,
        'Rejected': externalSubStatusOption6
      }
    }
  };