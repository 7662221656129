import React from "react";
import Switch from "./ToggleSwitch";

export const EditableCellSwitch = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  data,
  notify,
}) => {
  const [value, setValue] = React.useState(initialValue);
  const [isToggle, setIsToggle] = React.useState(
    value === "yes" ? true : false
  );
  const onChange = (e) => {
    const columns = [
      "total_exp",
      "current_company_type",
      "current_ctc_float",
      "min_expected_ctc_float",
      "skill",
      "notice_period_status_bool",
    ];
    const accessKeyMap = {
      total_exp: "Total Experience",
      current_company_type: "Current Company Type",
      current_ctc_float: "Current CTC",
      min_expected_ctc_float: "Expected CTC",
      skill: "Skill",
      notice_period_status_bool: "Notic Period",
    };
    var mark = true;
    var notValidKey = "";
    if (!isToggle) {
      columns.forEach((key) => {
        if (!data[index][key]) {
          mark = false;
          notValidKey = key;
        }
      });
    }
    if (mark) {
      const valueToSet = !isToggle ? "yes" : "no";
      setValue(valueToSet ? "yes" : "no");
      setIsToggle(!isToggle);
      updateData(index, id, valueToSet);
    } else {
      notify(accessKeyMap[notValidKey] + " is Mandatory");
    }
  };
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <Switch rounded={true} isToggle={isToggle} change={onChange}>
      {" "}
    </Switch>
  );
};
