import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import "./jdinterview.css";
import { insertIntoTable, loadTableData } from "../api";



function JdInterviewModal({rowValue,setIsModalVisible,rows,setRows}) {
    const [newRow, setNewRow] = useState({
        interviewType: "",
        interviewPOC: [],
        comments: "",
      });
  
  const handleAddRow = async(row) => {
    console.log(row)
    const data = {rows:[
        {
          jd_id: rowValue.jd_id,
          interview_type: row.interviewType,
          interview_poc: row.interviewPOC,
          comments : row.comments,
        }
      ]};
      const res = await insertIntoTable("jd_interview",data);
      const response = await loadTableData("jd_interview",{jd_id:rowValue.jd_id})
      console.log(response)
      setRows([...rows,{...newRow} ]);
  };

  const handleSave = () => {
    // You can save the `rows` array here with the updated data.
    console.log("Saved Data:", rows);
  };

  const handleInterviewTypeChange = (event, newValue, index) => {
    const updatedRows = [...rows];
    updatedRows[index].interviewType = newValue;
    setRows(updatedRows);
  };

  const handleInterviewPOCChange = (event, newValue, index) => {
    const updatedRows = [...rows];
    updatedRows[index].interviewPOC = newValue;
    setRows(updatedRows);
  };

  const handleChipDelete = (chipToDelete, index) => {
    const updatedRows = [...rows];
    updatedRows[index].interviewPOC = updatedRows[index].interviewPOC.filter(
      (chip) => chip !== chipToDelete
    );
    setRows(updatedRows);
  };

  const handleCommentsChange = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  return (
    <div>
      <table style={{marginTop:"2rem"}}>
        <thead >
          <tr>
            <th style={{ width: "10rem", border: "1px solid #ddd" }}>
              Interview Type
            </th>
            <th style={{ width: "10rem", border: "1px solid #ddd" }}>
              Interview POC
            </th>
            <th style={{ width: "10rem", border: "1px solid #ddd" }}>
              Comments
            </th>
            <th style={{ width: "10rem", border: "1px solid #ddd" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid #ddd" }}>
                <select
                  style={{ width: "10rem" }}
                  name="type"
                  value={row.interviewType}
                  onChange={(e) =>
                    handleInterviewTypeChange(e, e.target.value, index)
                  }
                >
                  <option value="">Select Type</option>
                  <option value="assignment">Assignment</option>
                  <option value="Interview">Interview</option>
                </select>
              </td>
              <td style={{ border: "1px solid #ddd" }}>
                <Autocomplete
                  className="autocomplete-custom-height"
                  multiple
                  id="multiselect-chips"
                  options={[
                    "tanmay@dashhire.co",
                    "shubham@dashhire.co",
                    "pratyushyadav@dashhire.co",
                    "abinashjha@dashhire.co",
                  ]}
                  value={row.interviewPOC}
                  onChange={(event, newValue) =>
                    handleInterviewPOCChange(event, newValue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, tagIndex) => (
                      <Chip
                        key={tagIndex}
                        label={option}
                        onDelete={() => handleChipDelete(option, index)}
                      />
                    ))
                  }
                />
              </td>
              <td style={{ border: "1px solid #ddd" }}>
                {" "}
                <input
                  type="text"
                  name="comments"
                  value={row.comments}
                  onChange={(e) => handleCommentsChange(e, index)}
                />
              </td>
              <td style={{ border: "1px solid #ddd" }}>
                {index === rows.length - 1 && (
                  <button variant="outlined" onClick={()=>handleAddRow(row)}>
                    Add
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <div>
        <button
          style={{
            marginRight: "1rem",
            borderRadius: "8px",
            width: "5rem",
            backgroundColor: "#17E5C3",
          }}
          variant="contained"
          onClick={handleSave}
        >
          Save
        </button>
      </div> */}
    </div>
  );
}

export default JdInterviewModal;
