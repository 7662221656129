import React, { useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useColumnOrder,
} from "react-table";
import { useParams, Link } from "react-router-dom";
import { useSticky } from "react-table-sticky";
import Modal from "../components/modal/Modal";
import { insertIntoTable, loadTableData } from "../api";
import { DashAutoComplete } from "../components/autocomplete";
import BulkSendModal, { handleSendBulk } from "../modals/bulkSendJdModal";
import {
  Confirm,
  Email,
  ProfileDetail,
  Remarks,
} from "../modals";
import JdModal from "../modals/jdModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { sendMail } from "../utility";
import { DateRangePicker } from "rsuite";
import { parseISO } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

const BASEURL = process.env.REACT_APP_BASE_URL;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span
      style={{
        fontSize: "1.8em",
        fontStyle: "bold",
        display: "block",
        margin: "12px",
      }}
    >
      {" "}
      Search:{" "}
      <input
        style={{
          marginBottom: "12px",
          height: "28px",
          width: "50%",
          fontSize: "0.8em",
        }}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </span>
  );
}

export const UploadFile = () => {
  const [selectedFile, setSelectedFile] = React.useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append("File", selectedFile);

    fetch(
      "https://freeimage.host/api/1/upload?6d207e02198a847aa98d0a2a901485a5>",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("Success:", result);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  return (
    <div>
      <input type="file" name="file" onChange={changeHandler} />
      <div>
        <button onClick={handleSubmission}>Upload</button>
      </div>
    </div>
  );
};
export const LinksInternal = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
}) => {
  const jd_id = data[index].jd_id;
  var link = "/internal/" + jd_id;
  return (
    <div>
      <Link to={link}>Internal</Link>
    </div>
  );
};

export const LinksExternal = ({
  value: initialValue,
  row: { index },
  column: { id },
  data,
}) => {
  const jd_id = data[index].jd_id;
  var link = "/external/" + jd_id;
  return (
    <div>
      <Link to={link}>External</Link>
    </div>
  );
};

// Editable Cell Text
// Editable Cell Text

//Editable cell nam

//Editable cell emai

// Editable Cell Text

// Editable DropDowns

// Editable DropDown

// Editable DropDowns
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two


// Editable DropDowns
// Editable DropDowns




// Editable Toggle switch

// Editable Toggle switch

// export const CustomeDateformat = ({
//   value: initialValue,
//   row: { index },
//   column: { id },
// }) => {
//   const timeStamp = initialValue instanceof Date ? initialValue : new Date(initialValue);

//   // Format date as "YYYY-MM-DD" string for sorting
//   const dateFormat = timeStamp.toISOString().slice(0, 10);

//   // Display the formatted date as per your preference
//   const formattedDate = `${timeStamp.getDate()}-${timeStamp.getMonth() + 1}-${timeStamp.getFullYear()}`;

//   return <span>{initialValue ? formattedDate : ""}</span>;
// };


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <input
      style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }} // Set undefined to remove the filter entirely
      placeholder={`Search ${count} records...`}
      onClick={(e) => e.stopPropagation()}
    />
  );
}

export function ColumnFilterForInternalNotice({
  column: { filterValue, preFilteredRows, setFilter },
  JdCompanyName,
}) {
  const count = preFilteredRows.length;
  // console.log(JdCompanyName);
  return (
    <div>
      <input
        style={{ margin: "2px", maxWidth: "150px", fontSize: "0.85rem" }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }} // Set undefined to remove the filter entirely
        placeholder={`Search ${count} records...`}
        onClick={(e) => e.stopPropagation()}
      />
      <div style={{ border: "1px solid green" }}>
        <span style={{ color: "red", fontSize: "100%", paddingBottom: "5px" }}>
          ReQ:{" "}
        </span>
        <span>{JdCompanyName.max_notice_period}</span>
      </div>
    </div>
  );
}

export function dateSortType(rowA, rowB, columnId, desc) {
  const valueA = parseISO(rowA.values[columnId]);
  const valueB = parseISO(rowB.values[columnId]);
  if (valueA < valueB) return desc ? 1 : -1;
  if (valueA > valueB) return desc ? -1 : 1;
  return 0;
}

export function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0];
  const ed = filterValues[1];

  return rows.filter((r) => {
    const value = r.values[id];
    const time = value instanceof Date ? value.getTime() : Date.parse(value);
    if (isNaN(time)) return false;
    return time >= sd && time <= ed;
  });
}

// export function dateBetweenFilterFn(rows, id, filterValues) {
//   console.log(filterValues);
//   const sd = filterValues[0];
//   const ed = filterValues[1];
//   console.log(sd,ed)
//   return rows.filter((r) => {
//      console.log(new Date(r.values[id]));
//     const time = new Date(r.values[id]).getTime();
//     if (filterValues.length === 0) return rows;
//     return time >= sd && time <= ed;
//   });
// }

export function statusFilterFn(rows, id, filterValue) {
  //console.log("Filtering",filterValue)
  if (id[0] === "internal_status") {
    if (filterValue.toLowerCase() === "no") {
      //console.log("Full Filter")
      return rows.filter((r) => {
        if (
          r.values[id] !== null &&
          r.values[id].toLowerCase() === filterValue.toLowerCase()
        )
          return true;
      });
    } else if (filterValue.toLowerCase === "new") {
      return rows.filter((r) => {
        if (
          r.values[id] === null ||
          r.values[id].toLowerCase() === filterValue.toLowerCase()
        )
          return true;
      });
    } else {
      return rows.filter((r) => {
        if (
          r.values[id] !== null &&
          r.values[id].toLowerCase().includes(filterValue.toLowerCase())
        )
          return true;
      });
    }
  } else {
    return rows.filter((r) => {
      if (
        r.values[id] === null ||
        r.values[id].toLowerCase().includes(filterValue.toLowerCase())
      )
        return true;
    });
  }
}

function Table({
  columns,
  data,
  updateData,
  JdCompanyName,
  notify,
  changeLog,
  addRemark,
  sortedFn,
  pageType,
  callFetchCandidatesMethod,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [jdData, setJdData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpens, setIsModalOpens] = React.useState(false);
  const [isBulkSendModalOpen, setIsBulkSendModalOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const { jd_id } = useParams();

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setColumnOrder,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      updateData,
      notify,
      changeLog,
      addRemark,
      initialState: sortedFn,
      autoResetFilters: false,
      autoResetSortBy: false,
      JdCompanyName,
      autoResetPage: false,
      pageType,
      callFetchCandidatesMethod,
    },
    useFilters, // Need to before global!
    useGlobalFilter,
    useSortBy,
    usePagination,
    // useBlockLayout,
    useColumnOrder
  );
  const updatedDetail = (params) => {
    updateData(params.index, params.id, params.value);
  };

  React.useEffect(() => {
    console.log("Table data", data);
  }, []);
  // Render the UI for your table
  // const firstPageRows = rows.slice(0, 50)
  const handleRowSelection = (row) => {
    if (pageType === "Candidate") {
      const rowId = row.original.id;
      if (selectedRows.some((selectedRow) => selectedRow.id === rowId)) {
        setSelectedRows(
          selectedRows.filter((selectedRow) => selectedRow.id !== rowId)
        );
      } else {
        setSelectedRows([...selectedRows, row.original]);
      }
    } else {
      const rowId = row.original.candidate_id;
      if (
        selectedRows.some((selectedRow) => selectedRow.candidate_id === rowId)
      ) {
        setSelectedRows(
          selectedRows.filter(
            (selectedRow) => selectedRow.candidate_id !== rowId
          )
        );
      } else {
        setSelectedRows([...selectedRows, row.original]);
      }
      console.log(row);
    }
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      // Select all rows
      const allRows = data.map((row) => row);
      setSelectedRows(allRows);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleModelOpen = async () => {
    const { result } = await loadTableData("jd_data_details", {});
    setJdData(result);
    setIsModalOpen(true);
  };
  const handleBulkSend = async () => {
    // const { result } = await loadTableData("jd_data_details", {});
    setIsBulkSendModalOpen(true);
  };
  const handleBulkJDSend = async () => {
    for (let i = 0; i < selectedRows.length; i++) {
      sendMail(selectedRows[i]);
    }
    setIsBulkSendModalOpen(false);
  };
  const filteredSelectedRows = selectedRows.filter(
    (row) => console.log(row)
    // row.Company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    // row.Designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    // row.JDID.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div
          style={{
            marginTop: "20px",
            marginRight: "15px",
            width: "15%",
          }}
        >
          {pageType === "Candidate" ? (
            <button
              style={{
                backgroundColor: "#17E5C3",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "16px",
                fontStyle: "normal",
                marginLeft: "120px",
                width: "50%",
                height: "40px",
              }}
              onClick={handleModelOpen}
            >
              Move To JD
            </button>
          ) : pageType === "Internal" ? (
            <div style={{ display: "flex" }}>
              <div>
                <button
                  style={{
                    backgroundColor: "#17E5C3",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontStyle: "normal",
                    width: "100%",
                    height: "40px",
                  }}
                  onClick={handleBulkSend}
                >
                  Bulk Send JD
                </button>
              </div>
              <div>
                <button
                  style={{
                    backgroundColor: "#17E5C3",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontStyle: "normal",
                    marginLeft: "20px",
                    width: "100%",
                    height: "40px",
                  }}
                  onClick={handleModelOpen}
                >
                  Move To JD
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {isBulkSendModalOpen ? (
            <Modal
              handleClose={() => setIsBulkSendModalOpen(false)}
              isOpen={isBulkSendModalOpen}
            >
             <BulkSendModal 
             selectedRows={selectedRows} 
             setIsBulkSendModalOpen={setIsBulkSendModalOpen}/>
            </Modal>
          ) : (
            <Modal
              handleClose={() => setIsModalOpen(false)}
              isOpen={isModalOpen}
            >
              <JdModal
                selectedRows={selectedRows}
                jdData={jdData}
                isModalOpens={isModalOpens}
                setIsModalOpens={setIsModalOpens}
              />
            </Modal>
          )}
        </div>
      </div>
      <table {...getTableProps()}>
        <thead
          style={{
            position: "sticky",
            top: "74px",
            background: "white",
            zIndex: "100",
            borderBottom: "2px solid black",
          }}
        >
          <tr>
            {pageType === "Candidate" || pageType === "Internal" ? (
              <th>
                <input
                  type="checkbox"
                  checked={selectedRows.length === data.length}
                  onChange={handleHeaderCheckboxChange}
                />
              </th>
            ) : (
              ""
            )}
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                  </span>
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {pageType === "Candidate" || pageType === "Internal" ? (
                  <td>
                    <input
                      type="checkbox"
                      style={{ width: 50 }}
                      onChange={() => handleRowSelection(row)}
                      checked={
                        pageType === "Internal"
                          ? selectedRows.some(
                              (selectedRow) =>
                                selectedRow.candidate_id ===
                                row.original.candidate_id
                            )
                          : selectedRows.some(
                              (selectedRow) =>
                                selectedRow.id === row.original.id
                            )
                      }
                    />
                  </td>
                ) : (
                  ""
                )}
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />

      <ul className="pagination">
        <li
          className="page-item"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <a className="page-link">First</a>
        </li>
        <li
          className="page-item"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <a className="page-link">{"<"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <a className="page-link">{">"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <a className="page-link">Last</a>
        </li>
        <li>
          <a className="page-link">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </a>
        </li>
        <li>
          <a className="page-link">
            <input
              className="form-control"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px", height: "20px" }}
            />
          </a>
        </li>{" "}
        <select
          className="form-control"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          style={{ width: "120px", height: "38px" }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </ul>
    </>
  );
}

export default Table;
