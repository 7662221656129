import React, { useState, useEffect } from "react"; 
import { useNavigate } from 'react-router-dom';

const CandidateShort = (data) => {
  const navigate = useNavigate();

  const onShortlistButton=()=>{

  }

  const onRejectButton=()=>{

  }
  const onCandidateSelect=()=>{
    navigate("/candidate-details/5050300d-52c6-4ac0-aa38-67760034fe52")
  }

  return (

    <div>
            {  console.log(data.data)}
    <div className="candidate-short-container" onClick={onCandidateSelect}>  
    <div>{data.data["name"]} {data.name}</div>
        <div>
        <span>{data.data["designation"]}</span> at <span>{data.data["funding"]}</span> startup
        </div>
        <div>
        <ul>
        {data.data["tags"].map((tag)=> (
        <li key={tag}>
          <span>{tag}</span>
        </li>
        ))}
        </ul>
        </div>
    </div>
     <div className="button-layer">
      <button className="reject-button" onClick={onRejectButton}>Drop Candidate</button>
      <button className="submit-button" onClick={onShortlistButton}>Send to Dash Screen</button>
    </div>
    </div>
  );
};

export default CandidateShort;
