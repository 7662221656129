import React from "react";
import { sendWhatsAppMsg } from "../utility";

export const WhatsApp = ({
    value: initialValue,
    row: { index },
    column: { id },
    data,
  }) => {
    return (
      <div>
        <button className="btn" onClick={() => sendWhatsAppMsg(data[index])}>
          Send
        </button>
      </div>
    );
  };