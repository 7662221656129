import React from "react";

export function Hike({profDetail,updateProfileDetail}) {
  return (
    <>
      <div className="col-4">
        <label>Hike(%)</label>
      </div>
      <div className="col-4">
        <input
          type="text"
          name="hike"
          value={profDetail.hike}
          className="form-control"
          onChange={(e) => updateProfileDetail(e)}
        />
      </div>
    </>
  );
}
