// src/App.js
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
import Header from "./header";
import { loadTableData, updateTableData } from "./api";
import { ColorRing } from "react-loader-spinner";
import Table from "./tableView/Table";
import { Styles } from "./tableView/table-css";
import { useAppColumns } from "./tableView/allCandidateColumn";

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch Data from DB
  async function FetchCandidates() {
    var tableName = "candidate_details";
    const { result, error } = await loadTableData(tableName, {});
    if (result?.length >= 0) {
      setData(result);
      setLoading(false);
    }
  }

  // Update data to DB
  async function UpdateTable(columnId, mobile, value) {
    const rows = {
      rows: [{ update: { [columnId]: value }, match: { mobile: mobile } }],
    };
    await updateTableData("candidates", rows);
  }

  // Update data after data change
  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          UpdateTable(columnId, row.mobile, value);
          return { ...old[rowIndex], [columnId]: value };
        }
        return row;
      })
    );
    setLoading(false);
  };

  const columns = useAppColumns();

  useEffect(() => {
    FetchCandidates();
  }, []);

  const notify = (notification) =>
    toast.error(notification, { position: toast.POSITION.TOP_CENTER });
  const sortedFn = { sortBy: [{ id: "shortlist_date", desc: true }] };

  return (
    <>
      <Header link={"candidates"} linktype={"CANDIDATE"} data={data} />
      <Styles>
        <div>
          {loading ? (
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              wrapperClass="blocks-wrapper"
              colors={["#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd", "#0d6efd"]}
            />
          ) : data.length !== 0 ? (
            <Table
              columns={columns}
              data={data}
              updateData={updateData}
              notify={notify}
              sortedFn={sortedFn}
              pageType={"Candidate"}
              callFetchCandidatesMethod={FetchCandidates}
            />
          ) : (
            ""
          )}
          <ToastContainer />
        </div>
      </Styles>
      <Footer />
    </>
  );
}

export default App;
